// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DateTime, Duration } from "luxon";
// -------------------------------------------------
// Redux actions
import { setAlert } from "../../redux/reducers/ui";
import { taskResponsesSelectors } from "../../redux/reducers/taskResponses";
import {
  surveysSelectors,
  mutateSurveyByObjId,
} from "../../redux/reducers/surveys";
// -------------------------------------------------
// Basic components
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { BarChart, XAxis, YAxis, Bar, ResponsiveContainer } from "recharts";
import HotelIcon from "@mui/icons-material/Hotel";
import ZzzIcon from "./ZzzIcon";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BatteryCharging20Icon from "@mui/icons-material/BatteryCharging20";
import BatteryCharging30Icon from "@mui/icons-material/BatteryCharging30";
import BatteryCharging50Icon from "@mui/icons-material/BatteryCharging50";
import BatteryCharging80Icon from "@mui/icons-material/BatteryCharging80";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
// =================================================
// FUNCTIONAL COMPONENT
const ConsensusSleepDiary = (props) => {
  // =================================================
  // VARIABLES
  // -------------------------------------------------
  // Local
  const colors = {
    inbed: "#194145C9",
    asleep: "#47226DC9",
    awake: "#8A287EAB",
  };
  const [procGraphData, setProcGraphData] = useState(null);
  const formatTime = (tick) => {
    return DateTime.fromMillis(tick).toFormat("HH:mm");
  };
  // -------------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const currentTicket = useSelector((state) => state.tickets.currentTicket);
  const currentResponse = useSelector((state) =>
    taskResponsesSelectors.selectById(
      state,
      currentTicket && currentTicket.responseId,
    ),
  );
  const currentSurvey = useSelector((state) =>
    surveysSelectors.selectById(state, currentTicket && currentTicket.surveyId),
  );
  // =================================================
  // FUNCTIONS
  // -------------------------------------------------
  // Sets the initial value of time inputs based on the answer on other time intputs
  const initializeTimeInputs = () => {
    if (currentResponse.data.CSD3) {
      // If the time to bed is set, then also initialize the 'time trying to sleep'
      dispatch(
        mutateSurveyByObjId({
          surveyId: currentSurvey._id,
          targetId: "3177a14-0671-fca3-b5d0-047ae4b67b",
          update: {
            options: {
              ampm: true,
              step: 5,
              initialValue: currentResponse.data.CSD3,
            },
          },
        }),
      );
      // And set the initial value for the wake up time
      dispatch(
        mutateSurveyByObjId({
          surveyId: currentSurvey._id,
          targetId: "15054d6-1fb4-a4a0-07b1-f14424f061d8",
          update: {
            options: {
              ampm: true,
              step: 5,
              initialValue: DateTime.fromISO(currentResponse.data.CSD3)
                .plus({ hours: 8 })
                .toISOTime({ includeOffset: false, suppressSeconds: true }),
            },
          },
        }),
      );
      // And set the initial value for the rise time
      dispatch(
        mutateSurveyByObjId({
          surveyId: currentSurvey._id,
          targetId: "dc20136-434-7273-0c1-3e4b31f818e6",
          update: {
            options: {
              ampm: true,
              step: 5,
              initialValue: DateTime.fromISO(currentResponse.data.CSD3)
                .plus({ hours: 8 })
                .toISOTime({ includeOffset: false, suppressSeconds: true }),
            },
          },
        }),
      );
    }
    if (currentResponse.data.CSD4) {
      // If the time trying to sleep is set, then also set the initial value for the wake up time
      dispatch(
        mutateSurveyByObjId({
          surveyId: currentSurvey._id,
          targetId: "15054d6-1fb4-a4a0-07b1-f14424f061d8",
          update: {
            options: {
              ampm: true,
              step: 5,
              initialValue: DateTime.fromISO(currentResponse.data.CSD4)
                .plus({ hours: 8 })
                .toISOTime({ includeOffset: false, suppressSeconds: true }),
            },
          },
        }),
      );
      // And set the initial value for the rise time
      dispatch(
        mutateSurveyByObjId({
          surveyId: currentSurvey._id,
          targetId: "dc20136-434-7273-0c1-3e4b31f818e6",
          update: {
            options: {
              ampm: true,
              step: 5,
              initialValue: DateTime.fromISO(currentResponse.data.CSD4)
                .plus({ hours: 8 })
                .toISOTime({ includeOffset: false, suppressSeconds: true }),
            },
          },
        }),
      );
    }
    if (currentResponse.data.CSD8) {
      // If the final wake time is set, then also set the initial value for the rise time
      dispatch(
        mutateSurveyByObjId({
          surveyId: currentSurvey._id,
          targetId: "dc20136-434-7273-0c1-3e4b31f818e6",
          update: {
            options: {
              ampm: true,
              step: 5,
              initialValue: currentResponse.data.CSD8,
            },
          },
        }),
      );
    }
    if (currentResponse.data.CSD9) {
      // If the snooze duration is set, then also set the initial value for the rise time
      dispatch(
        mutateSurveyByObjId({
          surveyId: currentSurvey._id,
          targetId: "dc20136-434-7273-0c1-3e4b31f818e6",
          update: {
            options: {
              ampm: true,
              step: 5,
              initialValue: DateTime.fromISO(currentResponse.data.CSD8)
                .plus(Duration.fromISO(currentResponse.data.CSD9))
                .toISOTime({ includeOffset: false, suppressSeconds: true }),
            },
          },
        }),
      );
    }
  };
  // -------------------------------------------------
  // Computes the graph data
  const handleSetProcGraphData = () => {
    if (
      currentResponse.data.CSD3 &&
      currentResponse.data.CSD4 &&
      currentResponse.data.CSD5 &&
      currentResponse.data.CSD6 &&
      currentResponse.data.CSD8 &&
      currentResponse.data.CSD10
    ) {
      let tmpGraphData = [
        {
          type: "IN-BED",
          startTime: currentResponse.data.CSD3,
          endTime: currentResponse.data.CSD10,
        },
        {
          type: "WAKE/SLEEP",
          startTime: currentResponse.data.CSD4,
          endTime: currentResponse.data.CSD8,
          sol: currentResponse.data.CSD5,
          waso: currentResponse.data.CSD7,
        },
      ];
      tmpGraphData = tmpGraphData.map((entry) => {
        // Ensure riseTime is after bedTime
        let startDateTime = DateTime.fromFormat(entry.startTime, "HH:mm");
        let endDateTime = DateTime.fromFormat(entry.endTime, "HH:mm");
        if (endDateTime < startDateTime) {
          endDateTime = endDateTime.plus({ days: 1 });
        }
        let obj = {
          type: entry.type,
          startDateTime,
          endDateTime,
          duration: endDateTime.diff(startDateTime).milliseconds,
          waso: entry.waso ? Duration.fromISO(entry.waso).toMillis() : 0,
          offset: startDateTime.toMillis(),
          bar1: endDateTime.diff(startDateTime).milliseconds,
          bar2: 0,
          bar3: 0,
          bar4: 0,
        };
        if (entry.sol) {
          obj.bar1 = Duration.fromISO(entry.sol).toMillis();
          obj.bar2 = endDateTime.diff(startDateTime).milliseconds - obj.bar1;
          obj.duration = obj.bar2;
        }
        if (entry.waso) {
          obj = Array.from({ length: 3 }, (_, i) => i + 2).reduce((o, i) => {
            if (i % 2 === 0) {
              return {
                ...o,
                [`bar${i}`]: (obj.duration - obj.waso) / 2,
              };
            } else {
              return { ...o, [`bar${i}`]: obj.waso };
            }
          }, obj);
        }
        return obj;
      });
      if (tmpGraphData[0].offset > tmpGraphData[1].offset) {
        tmpGraphData[1].startDateTime = tmpGraphData[1].startDateTime.plus({
          days: 1,
        });
        tmpGraphData[1].endDateTime = tmpGraphData[1].endDateTime.plus({
          days: 1,
        });
        tmpGraphData[1].offset = tmpGraphData[1].offset + 24 * 60 * 60 * 1000;
      }
      tmpGraphData[0].color1 = colors.inbed;
      tmpGraphData[0].color2 = colors.inbed;
      tmpGraphData[1].color1 = colors.awake;
      tmpGraphData[1].color2 = colors.asleep;
      setProcGraphData(tmpGraphData);
    }
  };
  // -------------------------------------------------
  // Checks that the in-bed time is before eyes closed time
  const checkInBedEyesClosed = () => {
    if (currentResponse.data.CSD3 && currentResponse.data.CSD4) {
      let InBed = DateTime.fromISO(currentResponse.data.CSD3).toObject();
      let EyesClosed = DateTime.fromISO(currentResponse.data.CSD4).toObject();
      InBed = InBed.hour + InBed.minute / 60;
      EyesClosed = EyesClosed.hour + EyesClosed.minute / 60;
      if (InBed < 15) {
        InBed = InBed + 24;
      }
      if (EyesClosed < 15) {
        EyesClosed = EyesClosed + 24;
      }
      if (EyesClosed < InBed) {
        dispatch(
          setAlert({
            type: "snackbar",
            variant: "error",
            message: `Oops! You cannot try to go to sleep (${currentResponse.data.CSD4}) before you got into bed (${currentResponse.data.CSD3}).`,
            duration: 32000,
          }),
        );
      }
    }
  };
  // checks that the sleep oppotunity window is larger than waso
  const checkSowWaso = () => {
    if (
      currentResponse.data.CSD4 &&
      currentResponse.data.CSD5 &&
      currentResponse.data.CSD6 &&
      currentResponse.data.CSD8
    ) {
      let EyesClosed = DateTime.fromISO(currentResponse.data.CSD4);
      let FinAwake = DateTime.fromISO(currentResponse.data.CSD8);
      let SOW;
      if (FinAwake.diff(EyesClosed).valueOf() < 0) {
        SOW =
          FinAwake.plus({ days: 1 }).diff(EyesClosed).valueOf() / (60 * 1000);
      } else {
        SOW = FinAwake.diff(EyesClosed).valueOf() / (60 * 1000);
      }
      let SOL = currentResponse.data.CSD5
        ? Duration.fromISO(currentResponse.data.CSD5).valueOf() / (60 * 1000)
        : 0;
      let WASO = currentResponse.data.CSD7
        ? Duration.fromISO(currentResponse.data.CSD7).valueOf() / (60 * 1000)
        : 0;
      if (SOW - SOL - WASO < 0) {
        dispatch(
          setAlert({
            type: "snackbar",
            variant: "error",
            message: `It seems you spent more time awake (${Duration.fromObject({ minute: Math.round(SOL + WASO) }).toFormat("h'h' m'm'")}) than you had been trying to sleep. Is that correct?`,
            duration: 32000,
          }),
        );
      }
    }
  };
  // Checks that the final awakening time is before the rise time
  const checkFinAwakeRiseTime = () => {
    if (
      currentResponse.data.CSD4 &&
      currentResponse.data.CSD8 &&
      currentResponse.data.CSD10
    ) {
      let EyesClosed = DateTime.fromISO(currentResponse.data.CSD4).toMillis();
      let FinAwake = DateTime.fromISO(currentResponse.data.CSD8).toMillis();
      let SnoozeDur = currentResponse.data.CSD9
        ? Duration.fromISO(currentResponse.data.CSD9).toMillis()
        : 0;
      let RiseTime = DateTime.fromISO(currentResponse.data.CSD10).toMillis();
      // Final awakening and rise time must be after eyes closed
      if (FinAwake < EyesClosed) {
        FinAwake = DateTime.fromISO(currentResponse.data.CSD8)
          .plus({ days: 1 })
          .toMillis();
      }
      if (RiseTime < EyesClosed) {
        RiseTime = DateTime.fromISO(currentResponse.data.CSD10)
          .plus({ days: 1 })
          .toMillis();
      }
      if (currentResponse.data.CSD9 && RiseTime < FinAwake + SnoozeDur) {
        dispatch(
          setAlert({
            type: "snackbar",
            variant: "error",
            message: `Oops! It seems you got out of bed  at ${currentResponse.data.CSD10}, but were still snoozing until ${DateTime.fromISO(
              currentResponse.data.CSD8,
            )
              .plus(Duration.fromISO(currentResponse.data.CSD9))
              .toISOTime({
                includeOffset: false,
                suppressSeconds: true,
              })}`,
            duration: 32000,
          }),
        );
      }
      if (RiseTime < FinAwake) {
        dispatch(
          setAlert({
            type: "snackbar",
            variant: "error",
            message: `Oops! It seems your final awakening (${currentResponse.data.CSD8}) was after you got out of bed (${currentResponse.data.CSD10}).`,
            duration: 32000,
          }),
        );
      }
    }
  };
  // -------------------------------------------------
  // Set the value of some aliases based on the answer of others
  useEffect(() => {
    if (!currentTicket || !currentResponse) {
      return;
    }
    initializeTimeInputs();
    // Check that the 'in-bed' time is before the 'eyes-closed' time
    checkInBedEyesClosed();
    // Check that the amount of time awake between eyes-closed and final awakening is bigger than SOL and WASO combined
    checkSowWaso();
    // Check that the final awakening was before rise time
    checkFinAwakeRiseTime();
    // Construct the processed data to show the graph
    handleSetProcGraphData();
  }, [currentResponse]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------
  const sleepEfficiency =
    procGraphData && procGraphData[0] && procGraphData[1]
      ? Math.round(
          (100 * (procGraphData[1].duration - procGraphData[1].waso)) /
            procGraphData[0].duration,
        )
      : 0;
  // ===============================================
  // RENDER COMPONENT
  return (
    procGraphData && (
      <Card className="bg-white shadow p-3 mb-3 text-center">
        <Typography variant="h5">
          Please double check these times. Are they correct?
        </Typography>
        <Typography variant="body2" className="d-block mb-3">
          If not, please adjust your answers.
        </Typography>
        <ResponsiveContainer
          width="100%"
          height={98}
          className="mb-4 mx-auto"
          style={{ maxWidth: "450px" }}
        >
          <BarChart
            layout="vertical"
            data={procGraphData}
            barCategoryGap={1}
            barGap={0}
            barSize={20}
          >
            <XAxis
              xAxisId="top"
              orientation="top"
              minTickGap={0}
              axisLine={false}
              type="number"
              tick={{ fontSize: 16, fontWeight: "bold" }}
              height={16}
              domain={[
                Math.min(
                  ...procGraphData.map((d) =>
                    d.startDateTime.plus({ hour: -1 }),
                  ),
                ),
                Math.max(
                  ...procGraphData.map((d) => d.endDateTime.plus({ hour: 1 })),
                ),
              ]}
              ticks={[
                procGraphData[0].startDateTime.toMillis(),
                procGraphData[0].endDateTime.toMillis(),
              ]}
              tickFormatter={formatTime}
              allowDataOverflow
            />
            <XAxis
              xAxisId="bottom"
              orientation="bottom"
              minTickGap={0}
              axisLine={false}
              type="number"
              tick={{ fontSize: 16, fontWeight: "bold" }}
              height={16}
              domain={[
                Math.min(
                  ...procGraphData.map((d) =>
                    d.startDateTime.plus({ hour: -1 }),
                  ),
                ),
                Math.max(
                  ...procGraphData.map((d) => d.endDateTime.plus({ hour: 1 })),
                ),
              ]}
              ticks={[
                procGraphData[1].startDateTime.toMillis() +
                  procGraphData[1].bar1,
                procGraphData[1].endDateTime.toMillis(),
              ]}
              tickFormatter={formatTime}
              allowDataOverflow
            />
            <YAxis
              dataKey="type"
              minTickGap={0}
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12 }}
              width={64}
              type="category"
              allowDataOverflow
            />
            <Bar
              xAxisId="bottom"
              dataKey="offset"
              stackId="a"
              fillOpacity={0}
            />
            <Bar
              xAxisId="bottom"
              dataKey="bar1"
              stackId="a"
              fill={colors.awake}
              shape={(props) => {
                const { x, y, width, height, payload } = props;
                return (
                  <rect
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    fill={payload.color1}
                  />
                );
              }}
            />
            <Bar
              xAxisId="bottom"
              dataKey="bar2"
              stackId="a"
              fill={colors.asleep}
              shape={(props) => {
                const { x, y, width, height, payload } = props;
                return (
                  <rect
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    fill={payload.color2}
                  />
                );
              }}
            />
            <Bar
              xAxisId="bottom"
              dataKey="bar3"
              stackId="a"
              fill={colors.awake}
              shape={(props) => {
                const { x, y, width, height, payload } = props;
                return (
                  <rect
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    fill={payload.color1}
                  />
                );
              }}
            />
            <Bar
              xAxisId="bottom"
              dataKey="bar4"
              stackId="a"
              fill={colors.asleep}
              shape={(props) => {
                const { x, y, width, height, payload } = props;
                return (
                  <rect
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    fill={payload.color2}
                  />
                );
              }}
            />
          </BarChart>
        </ResponsiveContainer>
        <Grid container rowSpacing={3}>
          <Grid item xs={6} lg={3}>
            <ZzzIcon color={colors.asleep} className="d-inline me-2" />
            <Typography variant="caption" fontSize={14} color={colors.asleep}>
              Sleep time
            </Typography>
            <Typography variant="h6" color={colors.asleep}>
              {Duration.fromMillis(
                procGraphData[1].duration - procGraphData[1].waso,
              ).toFormat("h 'hrs' m 'min'")}
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3}>
            <VisibilityIcon
              className="d-inline me-2"
              sx={{ color: colors.awake }}
            />
            <Typography variant="caption" fontSize={14} color={colors.awake}>
              Nighttime wake
            </Typography>
            <Typography variant="h6" color={colors.awake}>
              {Duration.fromMillis(procGraphData[1].waso).toFormat(
                "h 'hrs' m 'min'",
              )}
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3}>
            <HotelIcon className="d-inline me-2" sx={{ color: colors.inbed }} />
            <Typography variant="caption" fontSize={14} color={colors.inbed}>
              In bed
            </Typography>
            <Typography variant="h6" color={colors.inbed}>
              {Duration.fromMillis(procGraphData[0].duration).toFormat(
                "h 'hrs' m 'min'",
              )}
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3}>
            {sleepEfficiency > 85 ? (
              <BatteryChargingFullIcon
                className="d-inline me-2"
                sx={{ color: "#000046" }}
              />
            ) : sleepEfficiency > 75 ? (
              <BatteryCharging80Icon
                className="d-inline me-2"
                sx={{ color: "#000046" }}
              />
            ) : sleepEfficiency > 65 ? (
              <BatteryCharging50Icon
                className="d-inline me-2"
                sx={{ color: "#000046" }}
              />
            ) : sleepEfficiency > 55 ? (
              <BatteryCharging30Icon
                className="d-inline me-2"
                sx={{ color: "#000046" }}
              />
            ) : (
              <BatteryCharging20Icon
                className="d-inline me-2"
                sx={{ color: "#000046" }}
              />
            )}
            <Typography variant="caption" fontSize={14} color="#000046">
              Sleep efficiency
            </Typography>
            <Typography variant="h6" color="#000046">
              {sleepEfficiency}%
            </Typography>
          </Grid>
        </Grid>
      </Card>
    )
  );
};
// =================================================
// EXPORT COMPONENT
export default ConsensusSleepDiary;
