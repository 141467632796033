// Example survey
export const defaultSurvey = {
  page: {
    _id: "",
    title: null,
    logic: null,
    gridContainerList: [
      {
        _id: "",
        title: null,
        logic: null,
        layout: {
          direction: "row",
          alignItems: "stretch",
          alignContent: "flex-start",
          justifyContent: "space-around",
          padding: { left: "0.5", right: "0.5", top: "0.5", bottom: "0.5" },
          rowSpacing: { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 },
          columnSpacing: { xs: 2, sm: 2, md: 2, lg: 2, xl: 2 },
          minHeight: "0",
          bgImage: "",
          bgImageOrigin: "border-box",
          bgImagePosition: "center top",
          bgImageRepeat: "no-repeat",
          bgImageSize: "cover",
          bgColor: "",
          bgOpacity: 1,
          classNames: "",
        },
        options: {
          isCard: true,
          hasShadow: true,
          isCollapsable: false,
          defaultCollapsed: true,
        },
        gridItemList: [
          {
            _id: "",
            title: null,
            logic: null,
            layout: {
              width: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }, // Only use in row-containers
              padding: { left: "0.5", right: "0.5", top: "0.5", bottom: "0.5" },
              minHeight: "0",
              bgImage: "",
              bgImageOrigin: "border-box",
              bgImagePosition: "center top",
              bgImageRepeat: "no-repeat",
              bgImageSize: "cover",
              bgColor: "",
              bgOpacity: 1,
              classNames: "",
            },
            options: {
              isCard: false,
              hasShadow: true,
              isCollapsable: false,
              defaultCollapsed: true,
            },
            articleList: null,
          },
        ],
      },
    ],
  },
};
export const defaultArticle = {
  _id: "",
  category: "",
  type: "text",
  required: true,
  encrypt: false,
  reverseValue: false,
  url: null,
  formLabel: "",
  inputLabel: "",
  title: null,
  content: null,
  instructions: null,
  rowList: null,
  columnList: null,
  answerList: null,
  logic: null,
  validation: null,
  layout: {
    noDivider: true,
    classNames: "",
  },
  options: null,
};
export const defaultAnswer = { text: "", value: "0", group: "" };
export const defaultRow = {
  _id: "",
  required: true,
  encrypt: false,
  reverseValue: false,
  text: "",
  url: "",
  formLabel: "",
  inputLabel: "",
  logic: null,
  validation: null,
  options: null,
};
export const defaultColumn = {
  _id: "",
  text: "",
  inputLabel: "",
};
const textboxValidation = [
  {
    type: "minCharCount",
    default: null,
    errorMsg: "",
    label: "Minimum character count",
  },
  {
    type: "maxCharCount",
    default: "280",
    errorMsg: "The number of characters must not exceed 280.",
    label: "Maximum character count",
  },
  {
    type: "regexp",
    default: null,
    errorMsg: "",
    label: "Test regular expression",
  },
];
const numberValidation = [
  {
    type: "minValue",
    default: null,
    errorMsg: "",
    label: "Minimum value",
  },
  {
    type: "maxValue",
    default: null,
    errorMsg: "",
    label: "Maximum value",
  },
  {
    type: "regexp",
    default: "^[+-]?(\\d+(\\.\\d*)?|\\.\\d+)([eE][+-]?\\d+)?$",
    errorMsg: "Only numbers are allowed.",
    label: "Test regular expression",
  },
];
export const defaultValidation = {
  textbox: textboxValidation,
  number: numberValidation,
};
export const defaultRowValidation = {
  "textbox-list": textboxValidation,
  "textbox-grid": textboxValidation,
  "number-list": numberValidation,
  "number-grid": numberValidation,
};
export const defaultOptions = {
  page: [],
  gridContainer: [
    {
      key: "isCard",
      default: true,
      type: "toggle",
      label: "Use card surface",
    },
    {
      key: "hasShadow",
      default: true,
      type: "toggle",
      label: "Shadow (only when using card surface)",
    },
    {
      key: "isCollapsable",
      default: false,
      type: "toggle",
      label: "Is collapsable",
    },
    {
      key: "defaultCollapsed",
      default: true,
      type: "toggle",
      label: "Collapsed by default",
    },
  ],
  gridItem: [
    {
      key: "isCard",
      default: false,
      type: "toggle",
      label: "Use card surface",
    },
    {
      key: "hasShadow",
      default: true,
      type: "toggle",
      label: "Shadow (only when using card surface)",
    },
    {
      key: "isCollapsable",
      default: false,
      type: "toggle",
      label: "Is collapsable",
    },
    {
      key: "defaultCollapsed",
      default: true,
      type: "toggle",
      label: "Collapsed by default",
    },
  ],
  text: [
    {
      key: "justifyContent",
      default: "space-around",
      type: "dropdown-menu",
      optionList: [
        { value: "flex-start", label: "Start" },
        { value: "flex-end", label: "End" },
        { value: "center", label: "Center" },
        { value: "space-around", label: "Space around" },
        { value: "space-between", label: "Space between" },
        { value: "space-evenly", label: "Space evenly" },
      ],
      label: "Align title and content in container",
    },
  ],
  image: [
    {
      key: "position",
      default: "center center",
      type: "dropdown-menu",
      optionList: [
        { value: "top left", label: "top left" },
        { value: "top center", label: "top center" },
        { value: "top right", label: "top right" },
        { value: "center left", label: "center left" },
        { value: "center center", label: "center center" },
        { value: "center right", label: "center right" },
        { value: "bottom left", label: "bottom left" },
        { value: "bottom center", label: "bottom center" },
        { value: "bottom right", label: "bottom right" },
      ],
      label: "Image position",
    },
    {
      key: "minHeight",
      default: "350px",
      type: "text",
      label: "Minimum height",
    },
    {
      key: "maxHeight",
      default: "350px",
      type: "text",
      label: "Maximum height",
    },
    {
      key: "blurredBackdrop",
      default: false,
      type: "toggle",
      label: "Add blurred backdrop to fill container",
    },
  ],
  "image-bento-box": [
    {
      key: "numColumns",
      default: "1",
      type: "number",
      label: "Number of columns",
    },
    {
      key: "rowHeight",
      default: "350",
      type: "number",
      label: "Row height (number only, in pixels)",
    },
  ],
  "image-bento-box-row": [
    {
      key: "colSpan",
      default: "1",
      type: "number",
      label: "Column span",
    },
    {
      key: "rowSpan",
      default: "1",
      type: "number",
      label: "Row span",
    },
  ],
  "video-file": [
    {
      key: "minHeight",
      default: "350px",
      type: "text",
      label: "Minimum height",
    },
    {
      key: "maxHeight",
      default: "350px",
      type: "text",
      label: "Maximum height",
    },
    {
      key: "autoPlay",
      default: false,
      type: "toggle",
      label: "Autoplay",
    },
    {
      key: "loop",
      default: false,
      type: "toggle",
      label: "Loop",
    },
    {
      key: "controls",
      default: true,
      type: "toggle",
      label: "Show controls",
    },
  ],
  "video-youtube": [
    {
      key: "minHeight",
      default: "350px",
      type: "text",
      label: "Minimum height",
    },
    {
      key: "maxHeight",
      default: "350px",
      type: "text",
      label: "Maximum height",
    },
    {
      key: "autoplay",
      default: false,
      type: "toggle",
      label: "Autoplay",
    },
    {
      key: "loop",
      default: false,
      type: "toggle",
      label: "Loop",
    },
    {
      key: "controls",
      default: true,
      type: "toggle",
      label: "Show controls",
    },
    {
      key: "allowFullScreen",
      default: true,
      type: "toggle",
      label: "Allow full screen",
    },
  ],
  textbox: [
    {
      key: "startAdornment",
      default: "",
      type: "text",
      label: "Input start adornment",
    },
    {
      key: "endAdornment",
      default: "",
      type: "text",
      label: "Input end adornment",
    },
  ],
  "textbox-long": null,
  "textbox-list": [
    {
      key: "firstColumnWidth",
      default: "30%",
      type: "text",
      label: "First column width (include unit e.g. '%' or 'px')",
    },
  ],
  "textbox-grid": [
    {
      key: "firstColumnWidth",
      default: "30%",
      type: "text",
      label: "First column width (include unit e.g. '%' or 'px')",
    },
  ],
  number: [
    {
      key: "startAdornment",
      default: "",
      type: "text",
      label: "Input start adornment",
    },
    {
      key: "endAdornment",
      default: "",
      type: "text",
      label: "Input end adornment",
    },
  ],
  "number-list": [
    {
      key: "firstColumnWidth",
      default: "30%",
      type: "text",
      label: "First column width (include unit e.g. '%' or 'px')",
    },
  ],
  "number-grid": [
    {
      key: "firstColumnWidth",
      default: "30%",
      type: "text",
      label: "First column width (include unit e.g. '%' or 'px')",
    },
  ],
  "radio-vertical": null,
  "radio-horizontal": [
    {
      key: "forceRow",
      default: false,
      type: "toggle",
      label: "Force horizontal radio-items on XS screens",
    },
  ],
  "radio-grid": [
    {
      key: "firstColumnWidth",
      default: "30%",
      type: "text",
      label: "First column width (include unit e.g. '%' or 'px')",
    },
    {
      key: "forceRow",
      default: false,
      type: "toggle",
      label: "Force horizontal radio-items on XS screens",
    },
  ],
  "dropdown-menu": null,
  "grouped-dropdown-menu": null,
  "dropdown-menu-list": [
    {
      key: "firstColumnWidth",
      default: "30%",
      type: "text",
      label: "First column width (include unit e.g. '%' or 'px')",
    },
  ],
  "dropdown-menu-grid": [
    {
      key: "firstColumnWidth",
      default: "30%",
      type: "text",
      label: "First column width (include unit e.g. '%' or 'px')",
    },
  ],
  checkboxes: null,
  "checkboxes-grid": [
    {
      key: "firstColumnWidth",
      default: "30%",
      type: "text",
      label: "First column width (include unit e.g. '%' or 'px')",
    },
  ],
  duration: [
    {
      key: "showDays",
      default: false,
      type: "toggle",
      label: "Show days",
    },
    {
      key: "showHours",
      default: true,
      type: "toggle",
      label: "Show hours",
    },
    {
      key: "showMinutes",
      default: true,
      type: "toggle",
      label: "Show minutes",
    },
    {
      key: "showSeconds",
      default: false,
      type: "toggle",
      label: "Show seconds",
    },
  ],
  date: [
    {
      key: "dateTimeFormat",
      default: "dd-MM-yyyy",
      type: "dropdown-menu",
      optionList: [
        { value: "dd-MM-yyyy", label: "dd-mm-yyyy (e.g. 31-12-2021)" },
        { value: "dd/MM/yyyy", label: "dd/mm/yyyy (e.g. 31/12/2021)" },
        { value: "MM-dd-yyyy", label: "mm-dd-yyyy (e.g. 12-31-2021)" },
        { value: "MM/dd/yyyy", label: "mm/dd/yyyy (e.g. 12/31/2021)" },
      ],
      label: "Date format",
    },
    {
      key: "min",
      default: "1900-01-01",
      type: "date",
      label: "Minimum date",
    },
    {
      key: "max",
      default: "2099-12-31",
      type: "date",
      label: "Maximum date",
    },
    {
      key: "disablePast",
      default: false,
      type: "toggle",
      label: "Disable past",
    },
    {
      key: "disableFuture",
      default: false,
      type: "toggle",
      label: "Disable future",
    },
    {
      key: "showTodayButton",
      default: true,
      type: "toggle",
      label: "Show 'today' button",
    },
  ],
  time: [
    {
      key: "showTodayButton",
      default: true,
      type: "toggle",
      label: "Show 'now' button",
    },
    {
      key: "ampm",
      default: process.env.REACT_APP_TITLE === "Australian Sleep Registry",
      type: "toggle",
      label: "AM/PM (on) or 24h (off)",
    },
    {
      key: "step",
      default: 5,
      type: "number",
      label: "Minutes step",
    },
    {
      key: "initialValue",
      default: null,
      type: "text",
      label: "Default initial value",
    },
  ],
  "date-time": [
    {
      key: "dateTimeFormat",
      default: "dd-MM-yyyy HH:mm",
      type: "dropdown-menu",
      optionList: [
        {
          value: "dd-MM-yyyy HH:mm",
          label: "dd-mm-yyyy hh:mm (e.g. 31-12-2021 12:34)",
        },
        {
          value: "dd/MM/yyyy HH:mm",
          label: "dd/mm/yyyy hh:mm (e.g. 31/12/2021 12:34)",
        },
        {
          value: "MM-dd-yyyy HH:mm",
          label: "mm-dd-yyyy hh:mm (e.g. 12-31-2021 12:34)",
        },
        {
          value: "MM/dd/yyyy HH:mm",
          label: "mm/dd/yyyy hh:mm (e.g. 12/31/2021 12:34)",
        },
      ],
      label: "Date-time format",
    },
    {
      key: "min",
      default: "1900-01-01",
      type: "date",
      label: "Minimum date",
    },
    {
      key: "max",
      default: "2099-12-31",
      type: "date",
      label: "Maximum date",
    },
    {
      key: "disablePast",
      default: false,
      type: "toggle",
      label: "Disable past",
    },
    {
      key: "disableFuture",
      default: false,
      type: "toggle",
      label: "Disable future",
    },
    {
      key: "showTodayButton",
      default: true,
      type: "toggle",
      label: "Show 'today' button",
    },
    {
      key: "ampm",
      default: true,
      type: "toggle",
      label: "AM/PM (on) or 24h (off)",
    },
    {
      key: "step",
      default: 5,
      type: "number",
      label: "Minutes step",
    },
  ],
  "slider-horizontal": [
    {
      key: "startAdornment",
      default: "",
      type: "text",
      label: "Input start adornment",
    },
    {
      key: "endAdornment",
      default: "",
      type: "text",
      label: "Input end adornment",
    },
    {
      key: "min",
      default: 0,
      type: "number",
      label: "Minimum value",
    },
    {
      key: "max",
      default: 100,
      type: "number",
      label: "Maximum value",
    },
    {
      key: "step",
      default: 10,
      type: "number",
      label: "Step",
    },
    {
      key: "marks",
      default: true,
      type: "toggle",
      label: "Show tick marks",
    },
    {
      key: "intervals",
      default: 10,
      type: "number",
      label: "Tick mark intervals",
    },
    {
      key: "unit",
      default: "",
      type: "text",
      label: "Unit label (e.g. *C)",
    },
  ],
};
export const defaultRowOptions = {
  "image-bento-box": {
    colSpan: "1",
    rowSpan: "1",
  },
  checkboxes: null,
  "checkboxes-grid": null,
  "radio-grid": null,
  "dropdown-menu-list": null,
  "dropdown-menu-grid": null,
  "textbox-list": {
    startAdornment: "",
    endAdornment: "",
  },
  "textbox-grid": {
    startAdornment: "",
    endAdornment: "",
  },
  "number-list": {
    startAdornment: "",
    endAdornment: "",
  },
  "number-grid": {
    startAdornment: "",
    endAdornment: "",
  },
  "slider-horizontal": null,
};
