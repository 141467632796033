// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
// -------------------------------------------------
// Support functions
import { getTicketAvailability } from "../supportFunc/getTicketAvailability";
// -------------------------------------------------
// Context
import { useAuth } from "../contexts/auth";
import { useSocket } from "../contexts/socket";
// -------------------------------------------------
// Redux
import {
  setSlideDirection,
  toggleLiveValidation,
} from "../redux/reducers/form";
import {
  updateTicketByIdWithKeyValue,
  patchCurrentTicket,
  ticketsSelectors,
  setTicketAvailabilityList,
} from "../redux/reducers/tickets";
import { patchTaskResponses } from "../redux/reducers/taskResponses";
import { patchPreviewResponses } from "../redux/reducers/previewResponses";
import {
  notificationsSelectors,
  deleteCurrentNotification,
} from "../redux/reducers/notifications";
import { setAlert, togglePrimDrawer } from "../redux/reducers/ui";
// -------------------------------------------------
// Basic elements
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
// =================================================
// FUNCTIONAL COMPONENT
const FormButtons = (props) => {
  const { t } = useTranslation("components", { keyPrefix: "Form_Buttons" });
  // =================================================
  // VARIABLES
  // -----------------------------------------------
  // For navigating
  const navigate = useNavigate();
  // -------------------------------------------------
  // Contexts
  const { currentAuth } = useAuth();
  const { socket } = useSocket();
  // -------------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const isXS = useSelector((state) => state.ui.isXS);
  const formTicketId = useSelector((state) => state.form.ticketId);
  const currentTicket = useSelector((state) => state.tickets.currentTicket);
  const notificationList = useSelector((state) =>
    notificationsSelectors.selectAll(state),
  );
  const ticketList = useSelector((state) => ticketsSelectors.selectAll(state));
  const responses = useSelector(
    (state) =>
      state[currentTicket.responseCollection].entities[
        currentTicket.responseId
      ],
  );
  const ticketsStatus = useSelector((state) => state.tickets.status);
  const taskResponsesStatus = useSelector(
    (state) => state.taskResponses.status,
  );
  const views = useSelector((state) => state.form.views);
  const viewIdx = currentTicket.viewIdx;
  const isEnabled = useSelector((state) => state.form.isEnabled);
  const validation = useSelector((state) => state.form.validation);
  const status = useSelector((state) => state.form.status);
  const primDrawerIsOpen = useSelector((state) => state.ui.primDrawerIsOpen);
  const ghostUser = useSelector((state) => state.user.ghostUser);
  // =================================================
  // FUNCTIONS
  // -------------------------------------------------
  // Each time the view index changes, scroll to top
  useEffect(() => {
    let el;
    el = document.querySelector("#scroll-top");
    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "end" });
    }
    el = document.querySelector("#scroll-top-preview");
    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [viewIdx]);
  // -------------------------------------------------
  // Handles upserting the response data to the databse
  const handlePatchResponses = (isCompleted) => {
    let newResponses = JSON.parse(JSON.stringify(responses));
    // Set the start and end time if not already done
    if (!newResponses.dateStarted) {
      newResponses.dateStarted = DateTime.now()
        .startOf("second")
        .toUTC()
        .toISO({ suppressMilliseconds: true });
    }
    if (isCompleted) {
      newResponses.dateCompleted = DateTime.now()
        .startOf("second")
        .toUTC()
        .toISO({
          suppressMilliseconds: true,
        });
    }
    // Create data object
    const body = {
      data: newResponses,
    };
    try {
      switch (currentTicket.responseCollection) {
        case "taskResponses":
          dispatch(
            patchTaskResponses({
              socket,
              requestingUser: currentAuth,
              body,
            }),
          );
          break;
        case "previewResponses":
          dispatch(patchPreviewResponses({ body }));
          break;
        default:
          return;
      }
      return { success: true };
    } catch (error) {
      return { success: false, error };
    }
  };
  // -------------------------------------------------
  // Handles upserting the ticket to the databse
  const handlePatchCurrentTicket = (updates) => {
    if (currentTicket.isDummy) {
      return { success: true };
    }
    // Create data object
    const body = {
      data: { ...currentTicket, ...updates },
    };
    dispatch(
      patchCurrentTicket({
        socket,
        requestingUser: currentAuth,
        body,
      }),
    );
    return { success: true };
  };
  // -------------------------------------------------
  // Calculate the next view index, go forward or backwards
  const shiftViewIdx = (thisViewIdx, direction) => {
    if (
      thisViewIdx === null ||
      thisViewIdx === undefined ||
      (direction !== -1 && direction !== 1)
    ) {
      return -1;
    }
    // Determine next view
    let nextViewIdx = thisViewIdx;
    // If on mobile, simply the next index
    if (isXS) {
      nextViewIdx = nextViewIdx + direction;
    } else {
      // If it not a mobile view, find the view with the next/prev page
      let isDifferentPage = false;
      let cnt = 0;
      while (!isDifferentPage) {
        cnt = cnt + 1;
        if (nextViewIdx < 0 || nextViewIdx > views.length - 1) {
          break;
        }
        if (views[nextViewIdx].pageId !== views[thisViewIdx].pageId) {
          isDifferentPage = true;
        } else {
          nextViewIdx = nextViewIdx + direction;
        }
        // Insurance policy, whenever we're stuck in a while
        if (cnt > 9999) {
          break;
        }
      }
    }
    // Return the next view index
    return nextViewIdx;
  };
  // -------------------------------------------------
  // Helper function for the function 'findNextEnabledViewIdx'
  const extractPageFromSurveyById = (pageId) => {
    return props.currentSurvey.pageList.find((page) => page._id === pageId);
  };
  // -------------------------------------------------
  // Helper function for the function 'findNextEnabledViewIdx'
  // Check which articles are disabled...
  //  ... either because they're directly disabled, or all their items are disabled
  // Check which gridItems are disabled...
  //  ... either because they're directly disabled, or all their articles are disabled
  // Check which gridContainers are disabled...
  //  ... either because they're directly disabled, or all their gridItems are disabled
  // Finally, check if the page is disabled...
  //  ... either becuase it is itself disabled, or all the gridContainers are disabled.
  const reducePageIsDisabled = (page, nextViewIdx) => {
    return isEnabled[page._id]
      ? page.gridContainerList
          .filter(
            (gc) =>
              !isXS || views[nextViewIdx].gridContainerIds.includes(gc._id),
          )
          .map(
            (gc) =>
              isEnabled[gc._id]
                ? gc.gridItemList &&
                  gc.gridItemList
                    .filter(
                      (gi) =>
                        !isXS ||
                        views[nextViewIdx].gridItemIds.includes(gi._id),
                    )
                    .map(
                      (gi) =>
                        isEnabled[gi._id]
                          ? gi.articleList &&
                            gi.articleList
                              .filter(
                                (article) =>
                                  !isXS ||
                                  views[nextViewIdx].articleIds.includes(
                                    article._id,
                                  ),
                              )
                              .map(
                                (article) =>
                                  isEnabled[article._id] &&
                                  article.rowList &&
                                  article.type !== "checkboxes" &&
                                  article.type !== "textbox-list" &&
                                  article.type !== "slider-horizontal"
                                    ? article.rowList
                                        .filter(
                                          (row) =>
                                            !isXS ||
                                            row._id ===
                                              views[nextViewIdx].rowId,
                                        )
                                        .map((row) => !isEnabled[row._id])
                                        .every((bool) => bool) // Article is disabled if all items are disabled
                                    : !isEnabled[article._id], // Article itself is disabled
                              )
                              .every((bool) => bool) // Grid item is disabled if all articles are disabled
                          : !isEnabled[gi._id], // Grid item itself is disabled
                    )
                    .every((bool) => bool) // Grid container is disabled if all grid items are disabled
                : !isEnabled[gc._id], // Grid container itself is disabled
          )
          .every((bool) => bool) // Page is disabled if all grid containers are disabled
      : !isEnabled[page._id]; // Page itself is disabled
  };
  // -------------------------------------------------
  // Find the next/prev enabeld view
  const findNextEnabledViewIdx = (direction) => {
    // Start by assuming the next view is not enabled
    let nextViewIsDisabled = true;
    let nextViewIdx = viewIdx;
    // Check if next view is enabled, while it is not, get the next view etc.
    let cnt = 0;
    while (nextViewIsDisabled) {
      cnt = cnt + 1;
      // Get the next view index
      nextViewIdx = shiftViewIdx(nextViewIdx, direction);
      // If the view index is out of bounds, break from while loop
      if (nextViewIdx < 0 || nextViewIdx > views.length - 1) {
        break;
      }
      // Extract the page with all the containers, grid items, articles and article items
      const page = extractPageFromSurveyById(views[nextViewIdx].pageId);
      nextViewIsDisabled = reducePageIsDisabled(page, nextViewIdx);
      if (cnt > 100) {
        console.error(
          "Form_Buttons.js: findNextEnabledViewIdx() got stuck in while loop.",
        );
        break;
      }
    }
    // Crop the view index between 0 and length of views
    if (nextViewIdx < 0) {
      return -1;
    }
    if (nextViewIdx > views.length - 1) {
      return views.length;
    }
    return nextViewIdx;
  };
  // Handle things to do if there are invalid responses
  const handleInvalidResponses = (direction) => {
    if (currentTicket.isCompleted) {
      return false; // No checks needed, the ticket is completed
    }
    if (
      direction === 1 &&
      Object.keys(validation)
        .map((key) => validation[key])
        .some((val) => val !== null)
    ) {
      // Scroll to first error
      const firstInvalid = Object.keys(validation).find(
        (key) => validation[key] !== null,
      );
      if (firstInvalid) {
        document
          .querySelector(`[name=${firstInvalid}]`)
          .closest(".form-article")
          .scrollIntoView({ behavior: "smooth", block: "center" });
      }
      dispatch(toggleLiveValidation({ liveValidation: true })); // turn on live validation
      return true;
    } else {
      dispatch(toggleLiveValidation({ liveValidation: false }));
      return false;
    }
  };
  // -------------------------------------------------
  // Go forward or backwards
  const handleSetNextView = (direction) => {
    // -------------------------------------------------
    // First handle validation errors
    const hasValidationErrors = handleInvalidResponses(direction);
    if (hasValidationErrors) {
      return;
    }
    // -------------------------------------------------
    // No validation errors, or direction is backwards => get the next view index
    const nextViewIdx = findNextEnabledViewIdx(direction);
    // If the next index is in range: change the view index
    if (nextViewIdx >= 0 && nextViewIdx < views.length) {
      // Write ticket to DB
      const res1 = handlePatchCurrentTicket({
        hasStarted: true,
        viewIdx: nextViewIdx,
      });
      // Write responses to DB
      const res2 = handlePatchResponses(false);
      if (!res1.success || !res2.success) {
        dispatch(
          setAlert({
            type: "dialog",
            variant: "error",
            title: "A critical unexpected error occurred!",
            message: `Your response data has not been saved to the database. Please contact us or try again.`,
          }),
        );
        return;
      }
      dispatch(
        setSlideDirection({
          slideDirection: direction === 1 ? "left" : "right",
        }),
      );
      if (!currentTicket.hasStarted) {
        dispatch(
          updateTicketByIdWithKeyValue({
            ticketId: formTicketId,
            key: "hasStarted",
            value: true,
          }),
        );
      }
      dispatch(
        updateTicketByIdWithKeyValue({
          ticketId: formTicketId,
          key: "viewIdx",
          value: nextViewIdx,
        }),
      );
    } else {
      // This error should never occur.
      console.error(
        "Form_Buttons. handleSetNextView(). An unexpected error occurred. Next view index is out of bounds.",
      );
    }
  };
  // -------------------------------------------------
  // Complete this ticket
  const handleCompleteTicket = () => {
    // -------------------------------------------------
    // First handle validation errors
    const hasValidationErrors = handleInvalidResponses(1);
    if (hasValidationErrors) {
      return;
    }
    // -------------------------------------------------
    // Ok no validation errors, complete the ticket
    dispatch(
      updateTicketByIdWithKeyValue({
        ticketId: formTicketId,
        key: "isCompleted",
        value: true,
      }),
    );
    // Update the ticket availability list
    const tmp = ticketList.map((ticket) => getTicketAvailability(ticket));
    dispatch(setTicketAvailabilityList({ ticketAvailabilityList: tmp }));
    // Write ticket to DB
    handlePatchCurrentTicket({ hasStarted: true, isCompleted: true });
    // Write responses to DB
    handlePatchResponses(true);
    // Delete notification
    const notification = notificationList.find(
      (notif) => notif.entityId === formTicketId,
    );
    if (notification) {
      dispatch(
        deleteCurrentNotification({
          requestingUser: currentAuth,
          notificationId: notification._id,
        }),
      );
    }
    // Close main drawer
    handleCloseDrawer();
  };
  // -----------------------------------------------
  // Closes the drawer
  const handleCloseDrawer = () => {
    if (primDrawerIsOpen) {
      dispatch(togglePrimDrawer({ isOpen: false }));
    }
    if (currentTicket.responseCollection !== "previewResponses") {
      navigate("/tasks", { replace: true });
    }
  };
  // =================================================
  // RENDER COMPONENT
  // -------------------------------------------------
  return (
    <Grid
      container
      className={isXS ? "fixed-bottom bd-filt-blur-5px pb-3 px-2" : ""}
    >
      <Grid item xs={6} className="px-2 mb-2">
        <Button
          fullWidth
          size={isXS ? "large" : "medium"}
          disabled={
            status === "loading" ||
            ticketsStatus === "loading" ||
            taskResponsesStatus === "loading" ||
            findNextEnabledViewIdx(-1) < 0
          }
          variant="outlined"
          color="inherit"
          className="bg-white"
          onClick={() => handleSetNextView(-1)}
        >
          {t("Previous")}
        </Button>
      </Grid>
      <Grid item xs={6} className="px-2 mb-2">
        {findNextEnabledViewIdx(1) < views.length ? (
          <Button
            fullWidth
            size={isXS ? "large" : "medium"}
            disabled={
              status === "loading" ||
              ticketsStatus === "loading" ||
              taskResponsesStatus === "loading"
            }
            variant="contained"
            color="primary"
            onClick={() => handleSetNextView(1)}
          >
            {(status === "loading" ||
              ticketsStatus === "loading" ||
              taskResponsesStatus === "loading") && (
              <CircularProgress size="1.2rem" className="text-grey me-2" />
            )}
            {t("Continue")}
          </Button>
        ) : (
          <Button
            fullWidth
            size={isXS ? "large" : "medium"}
            disabled={
              status === "loading" ||
              ticketsStatus === "loading" ||
              taskResponsesStatus === "loading" ||
              (currentTicket.isCompleted &&
                !currentTicket.remainEditable &&
                !ghostUser)
            }
            variant="contained"
            color="primary"
            onClick={handleCompleteTicket}
          >
            {(status === "loading" ||
              ticketsStatus === "loading" ||
              taskResponsesStatus === "loading") && (
              <CircularProgress size="1.2rem" className="text-grey me-2" />
            )}
            {currentTicket.isCompleted &&
            !currentTicket.remainEditable &&
            !ghostUser
              ? t("Thanks! End of survey")
              : t("Complete")}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
// =================================================
// EXPORT COMPONENT
export default FormButtons;
