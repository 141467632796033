// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
// -------------------------------------------------
// Redux
import { studiesSelectors } from "../../redux/reducers/studies";
// -------------------------------------------------
// Component elements
import SurveyVersionMenuList from "../surveys/Surveys_SurveyVersionMenuList";
// -------------------------------------------------
// Basic elements
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import Add from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ButtonGroup from "@mui/material/ButtonGroup";
import DeleteForever from "@mui/icons-material/DeleteForever";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TimePicker } from "antd";
// =================================================
// FUNCTIONAL COMPONENT
const StudiesEditTimepointSurveys = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "studies.Studies_EditTimepointSurveys",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Local state
  const [warnings, setWarnings] = useState({});
  // -----------------------------------------------
  // Redux
  const studyList = useSelector((state) => studiesSelectors.selectAll(state));
  // Local state
  const [maxLevel, setMaxLevel] = useState(
    props.obj && props.obj.length > 0
      ? Math.max(...props.obj.map((o) => o.level))
      : 1,
  );
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Parse unit
  const parseUnit = (unit) => {
    return unit === "h"
      ? "hours"
      : unit === "d"
        ? "days"
        : unit === "w"
          ? "weeks"
          : "months";
  };
  // -----------------------------------------------
  // Sets the local state
  const handleSetObj = (idx, key, value) => {
    props.setObj((prevState) => {
      let newState = JSON.parse(JSON.stringify(prevState));
      newState[idx][key] = value;
      // Check if the availability is no longer than the repeat interval
      if (newState[idx].repeat !== 1) {
        // If availability is indefinate, set it to 1
        if (newState[idx].availability < 0) {
          newState[idx].availability = 1;
        }
        const now = DateTime.now();
        const repeatUnit = parseUnit(newState[idx].intervalUnit);
        const availUnit = parseUnit(newState[idx].availabilityUnit);
        const allowUnit = parseUnit(newState[idx].allowanceUnit);
        const repeatInterval = now
          .plus({ [repeatUnit]: newState[idx].interval })
          .diff(now)
          .valueOf();
        const availInterval = now
          .plus({ [availUnit]: newState[idx].availability })
          .plus({ [allowUnit]: newState[idx].allowance })
          .diff(now)
          .valueOf();
        if (availInterval > repeatInterval) {
          setWarnings((prevState) => {
            props.setHasErrors(true);
            return { ...prevState, [idx]: true };
          });
        } else {
          setWarnings((prevState) => {
            let newState = { ...prevState, [idx]: false };
            if (!Object.values(newState).some((w) => w)) {
              props.setHasErrors(false);
            }
            return { ...prevState, [idx]: false };
          });
        }
      }
      return newState;
    });
  };
  // -----------------------------------------------
  const handleAddItem = () => {
    props.setObj((prevState) => {
      let newState = {
        _id: uuid(),
        surveyId: Object.keys(props.surveyEntities)
          .filter(
            (key) =>
              props.surveyEntities[key].folder === "standardized" ||
              studyList.some(
                (study) => study._id === props.surveyEntities[key].folder,
              ),
          )
          .sort((k, m) =>
            props.surveyEntities[k].name.official <
            props.surveyEntities[m].name.official
              ? -1
              : 1,
          )[0],
        completer: "participant",
        remainVisible: false,
        remainEditable: false,
        level: maxLevel,
        delay: 1,
        repeat: 1,
        interval: 1,
        intervalUnit: "h",
        availability: -1,
        availabilityUnit: "h",
        allowance: 0,
        allowanceUnit: "h",
      };
      if (prevState) {
        newState = [...prevState, newState];
      } else {
        newState = [newState];
      }
      return newState;
    });
  };
  // -----------------------------------------------
  const handleDeleteItem = (idx) => {
    props.setObj((prevState) => {
      let newState = [...prevState];
      newState.splice(idx, 1);
      if (!newState || newState.length === 0) {
        newState = null;
      }
      return newState;
    });
  };
  dayjs.extend(customParseFormat);
  // ===============================================
  // RENDER COMPONENT
  return (
    <>
      <Grid container alignItems="flex-start">
        <Grid item xs={12} className="mb-2">
          <Typography variant="h4">{t("Measurements")}</Typography>
        </Grid>
        {props.obj &&
          props.obj.map((o, i) => (
            <React.Fragment key={`item-${i}`}>
              <Grid item xs={11} className="mb-2 pe-2">
                <Card className="shadow-none bg-light mb-2 border px-3 pt-3">
                  <SurveyVersionMenuList
                    surveyEntities={props.surveyEntities}
                    studyList={studyList}
                    selectedSurveyId={o.surveyId}
                    onSurveyIdChange={(surveyId) =>
                      handleSetObj(i, "surveyId", surveyId)
                    }
                  />
                  <Typography
                    paragraph
                    variant="caption"
                    color="textSecondary"
                    sx={{ fontSize: "0.8125rem" }}
                    className="m-0"
                  >
                    To be filled out by
                  </Typography>
                  <ToggleButtonGroup
                    value={o.completer}
                    exclusive
                    size="small"
                    className="mb-2"
                    onChange={(e, newValue) =>
                      newValue && handleSetObj(i, "completer", newValue)
                    }
                  >
                    <ToggleButton value="participant">Participant</ToggleButton>
                    <ToggleButton value="investigator">
                      Investigator
                    </ToggleButton>
                  </ToggleButtonGroup>

                  <FormControlLabel
                    control={
                      <Switch
                        checked={o.allowAnonymous ? true : false}
                        color="secondary"
                        onChange={(e) =>
                          handleSetObj(
                            i,
                            "allowAnonymous",
                            e.target.checked ? true : false,
                          )
                        }
                      />
                    }
                    label={
                      o.allowAnonymous ? (
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          sx={{ fontSize: "0.8125rem" }}
                        >
                          Allow anonymous responses
                        </Typography>
                      ) : (
                        <Typography
                          variant="caption"
                          color="textSecondary"
                          sx={{ fontSize: "0.8125rem" }}
                        >
                          Anonymous responses not allowed
                        </Typography>
                      )
                    }
                    className="ps-3 py-2"
                  />

                  <Typography
                    paragraph
                    variant="caption"
                    color="textSecondary"
                    sx={{ fontSize: "0.8125rem" }}
                    className="m-0"
                  >
                    After completion, the task
                  </Typography>
                  <ToggleButtonGroup
                    value={o.remainVisible}
                    exclusive
                    size="small"
                    className="mb-2"
                    onChange={(e, newValue) => {
                      handleSetObj(i, "remainVisible", newValue);
                      if (!newValue) {
                        handleSetObj(i, "remainEditable", newValue);
                      }
                    }}
                  >
                    <ToggleButton value={false}>Disappears</ToggleButton>
                    <ToggleButton value={true}>
                      Remains in task list
                    </ToggleButton>
                  </ToggleButtonGroup>
                  {o.remainVisible && (
                    <>
                      <Typography
                        paragraph
                        variant="caption"
                        color="textSecondary"
                        sx={{ fontSize: "0.8125rem" }}
                        className="m-0"
                      >
                        After completion, the task
                      </Typography>
                      <ToggleButtonGroup
                        value={o.remainEditable}
                        exclusive
                        size="small"
                        className="mb-2"
                        onChange={(e, newValue) =>
                          handleSetObj(i, "remainEditable", newValue)
                        }
                      >
                        <ToggleButton value={false}>
                          Is no longer editable
                        </ToggleButton>
                        <ToggleButton value={true}>
                          Remains editable
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </>
                  )}
                  <TextField
                    fullWidth
                    label="level"
                    type="number"
                    name="level"
                    variant="filled"
                    size="small"
                    value={o.level ? o.level : ""}
                    color="secondary"
                    InputProps={{
                      inputProps: { min: 1 },
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon className="me-2 align-middle" fontSize="small">
                            low_priority
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                    className="answer-text-green"
                    onChange={(e) => {
                      parseInt(e.target.value) > maxLevel &&
                        setMaxLevel(parseInt(e.target.value));
                      e.target.value === ""
                        ? handleSetObj(i, "level", null)
                        : handleSetObj(i, "level", parseInt(e.target.value));
                    }}
                    onWheel={(e) => {
                      if (document.activeElement === e.target) {
                        e.target.blur(); // Blur the input to stop unwanted changes
                        setTimeout(() => e.target.focus(), 0); // Restore focus without scroll effect
                      }
                    }}
                  />
                  {o.level === null && (
                    <Typography variant="caption" color="error">
                      Please specify level.
                    </Typography>
                  )}
                  <Grid container className="my-2">
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Start day"
                        type="number"
                        name="startDay"
                        variant="filled"
                        size="small"
                        value={Math.floor(o.delay)}
                        color="secondary"
                        InputProps={{
                          inputProps: { min: 1 },
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon
                                className="me-2 align-middle"
                                fontSize="small"
                              >
                                schedule_send
                              </Icon>
                            </InputAdornment>
                          ),
                        }}
                        className="pe-2 answer-text-green"
                        onChange={(e) => {
                          let newValue =
                            e.target.value === ""
                              ? 1
                              : Math.floor(parseInt(e.target.value));
                          newValue = newValue < 1 ? 1 : newValue;
                          handleSetObj(i, "delay", newValue + (o.delay % 1));
                        }}
                        onWheel={(e) => {
                          if (document.activeElement === e.target) {
                            e.target.blur(); // Blur the input to stop unwanted changes
                            setTimeout(() => e.target.focus(), 0); // Restore focus without scroll effect
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TimePicker
                        allowClear={false}
                        inputReadOnly
                        className="timepicker2mui"
                        value={dayjs("2020-01-01T00:00:00").add(
                          (o.delay % 1) * 24,
                          "hour",
                        )}
                        placeholder="Click to select a time"
                        size="small"
                        format="HH:mm"
                        minuteStep={5}
                        use12Hours={false}
                        showNow={false}
                        style={{ padding: "13px 12px 12px 12px" }}
                        onCalendarChange={(newValue) => {
                          const v =
                            Math.floor(o.delay) +
                            newValue.diff(dayjs("2020-01-01T00:00:00")) /
                              (24 * 60 * 60 * 1000);
                          handleSetObj(i, "delay", v);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Divider className="mt-1 mb-2" />
                  {warnings[i] && (
                    <Alert
                      severity="error"
                      className="border border-white mb-1"
                    >
                      The availability duration must be shorter than the repeat
                      interval.
                    </Alert>
                  )}
                  <Grid container alignItems="center">
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={o.repeat !== 1}
                            color="secondary"
                            onChange={(e) =>
                              handleSetObj(
                                i,
                                "repeat",
                                e.target.checked ? 2 : 1,
                              )
                            }
                          />
                        }
                        label={
                          o.repeat !== 1 ? (
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              sx={{ fontSize: "0.8125rem" }}
                            >
                              Allow repeats
                            </Typography>
                          ) : (
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              sx={{ fontSize: "0.8125rem" }}
                            >
                              No repeats
                            </Typography>
                          )
                        }
                        className="py-2"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        disabled={o.repeat === -1}
                        label="Number of repeats"
                        type="number"
                        name="repeat"
                        variant="filled"
                        size="small"
                        value={o.repeat ? o.repeat : ""}
                        color="secondary"
                        InputProps={{
                          inputProps: { min: 1 },
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon
                                className="me-2 align-middle"
                                fontSize="small"
                              >
                                repeat
                              </Icon>
                            </InputAdornment>
                          ),
                        }}
                        className="answer-text-green"
                        onChange={(e) =>
                          e.target.value !== "" && e.target.value !== "0"
                            ? handleSetObj(
                                i,
                                "repeat",
                                parseInt(e.target.value),
                              )
                            : handleSetObj(i, "repeat", null)
                        }
                        onWheel={(e) => {
                          if (document.activeElement === e.target) {
                            e.target.blur(); // Blur the input to stop unwanted changes
                            setTimeout(() => e.target.focus(), 0); // Restore focus without scroll effect
                          }
                        }}
                      />
                      {o.repeat === null && (
                        <Typography variant="caption" color="error">
                          Please specify number of repeats.
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={6} className="pt-2">
                      {o.repeat !== 1 && (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={o.repeat === -1}
                              color="secondary"
                              onChange={(e) =>
                                handleSetObj(
                                  i,
                                  "repeat",
                                  e.target.checked ? -1 : 2,
                                )
                              }
                            />
                          }
                          label={
                            o.repeat === -1 ? (
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                sx={{ fontSize: "0.8125rem" }}
                              >
                                Infinite repeats
                              </Typography>
                            ) : (
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                sx={{ fontSize: "0.8125rem" }}
                              >
                                Finite repeats
                              </Typography>
                            )
                          }
                        />
                      )}
                    </Grid>
                    <Grid item xs={3} className="pt-2">
                      {o.repeat !== 1 && (
                        <TextField
                          fullWidth
                          label="Repeat interval"
                          type="number"
                          name="interval"
                          variant="filled"
                          size="small"
                          value={o.interval ? o.interval : ""}
                          color="secondary"
                          InputProps={{
                            inputProps: { min: 1 },
                          }}
                          className="pe-2 answer-text-green"
                          onChange={(e) =>
                            e.target.value !== "" &&
                            parseInt(e.target.value) > 0
                              ? handleSetObj(
                                  i,
                                  "interval",
                                  parseInt(e.target.value),
                                )
                              : handleSetObj(i, "interval", null)
                          }
                          onWheel={(e) => {
                            if (document.activeElement === e.target) {
                              e.target.blur(); // Blur the input to stop unwanted changes
                              setTimeout(() => e.target.focus(), 0); // Restore focus without scroll effect
                            }
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3} className="pt-2">
                      {o.repeat !== 1 && (
                        <ToggleButtonGroup
                          value={o.intervalUnit}
                          exclusive
                          size="small"
                          className="mb-2"
                          onChange={(e, newValue) =>
                            newValue &&
                            handleSetObj(i, "intervalUnit", newValue)
                          }
                        >
                          <ToggleButton value="h">h</ToggleButton>
                          <ToggleButton value="d">d</ToggleButton>
                          <ToggleButton value="w">w</ToggleButton>
                          <ToggleButton value="m">m</ToggleButton>
                        </ToggleButtonGroup>
                      )}
                    </Grid>
                    {o.interval === null && (
                      <>
                        <Grid item xs={6} />
                        <Grid item xs={6}>
                          <Typography variant="caption" color="error">
                            Please specify interval duration.
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Divider className="my-2" />
                  <Grid container alignItems="center" className="pb-2">
                    <Grid item xs={6}>
                      <FormControlLabel
                        disabled={o.repeat !== 1}
                        control={
                          <Switch
                            checked={o.availability === -1}
                            color="secondary"
                            onChange={(e) =>
                              handleSetObj(
                                i,
                                "availability",
                                e.target.checked ? -1 : 1,
                              )
                            }
                          />
                        }
                        label={
                          o.availability === -1 ? (
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              sx={{ fontSize: "0.8125rem" }}
                            >
                              Available indefinately
                            </Typography>
                          ) : (
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              sx={{ fontSize: "0.8125rem" }}
                            >
                              Limited availability
                            </Typography>
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      {o.availability !== -1 && (
                        <TextField
                          fullWidth
                          label="availability"
                          type="number"
                          name="availability"
                          variant="filled"
                          size="small"
                          value={o.availability ? o.availability : ""}
                          color="secondary"
                          InputProps={{
                            inputProps: { min: 1 },
                            startAdornment: (
                              <InputAdornment position="start">
                                <Icon
                                  className="me-2 align-middle"
                                  fontSize="small"
                                >
                                  lock_clock
                                </Icon>
                              </InputAdornment>
                            ),
                          }}
                          className="pe-2 answer-text-green"
                          onChange={(e) =>
                            e.target.value !== "" &&
                            parseInt(e.target.value) > 0
                              ? handleSetObj(
                                  i,
                                  "availability",
                                  parseInt(e.target.value),
                                )
                              : handleSetObj(i, "availability", null)
                          }
                          onWheel={(e) => {
                            if (document.activeElement === e.target) {
                              e.target.blur(); // Blur the input to stop unwanted changes
                              setTimeout(() => e.target.focus(), 0); // Restore focus without scroll effect
                            }
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      {o.availability !== -1 && (
                        <ToggleButtonGroup
                          value={o.availabilityUnit}
                          exclusive
                          size="small"
                          className="mb-2"
                          onChange={(e, newValue) =>
                            newValue &&
                            handleSetObj(i, "availabilityUnit", newValue)
                          }
                        >
                          <ToggleButton value="h">h</ToggleButton>
                          <ToggleButton value="d">d</ToggleButton>
                          <ToggleButton value="w">w</ToggleButton>
                          <ToggleButton value="m">m</ToggleButton>
                        </ToggleButtonGroup>
                      )}
                    </Grid>
                    {o.availability === null && (
                      <>
                        <Grid item xs={6} />
                        <Grid item xs={6}>
                          <Typography variant="caption" color="error">
                            Please specify availability duration.
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  {o.availability !== -1 && (
                    <Grid container alignItems="center" className="pb-2">
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={o.allowance !== 0}
                              color="secondary"
                              onChange={(e) =>
                                handleSetObj(
                                  i,
                                  "allowance",
                                  e.target.checked ? 1 : 0,
                                )
                              }
                            />
                          }
                          label={
                            o.allowance !== 0 ? (
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                sx={{ fontSize: "0.8125rem" }}
                              >
                                Can be overdue by
                              </Typography>
                            ) : (
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                sx={{ fontSize: "0.8125rem" }}
                              >
                                Cannot be overdue
                              </Typography>
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        {o.allowance !== 0 && (
                          <TextField
                            fullWidth
                            label="allowance"
                            type="number"
                            name="allowance"
                            variant="filled"
                            size="small"
                            value={o.allowance ? o.allowance : ""}
                            color="secondary"
                            InputProps={{
                              inputProps: { min: 1 },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Icon
                                    className="me-2 align-middle"
                                    fontSize="small"
                                  >
                                    lock_clock
                                  </Icon>
                                </InputAdornment>
                              ),
                            }}
                            className="pe-2 answer-text-green"
                            onChange={(e) =>
                              e.target.value !== "" &&
                              parseInt(e.target.value) > 0
                                ? handleSetObj(
                                    i,
                                    "allowance",
                                    parseInt(e.target.value),
                                  )
                                : handleSetObj(i, "allowance", null)
                            }
                            onWheel={(e) => {
                              if (document.activeElement === e.target) {
                                e.target.blur(); // Blur the input to stop unwanted changes
                                setTimeout(() => e.target.focus(), 0); // Restore focus without scroll effect
                              }
                            }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        {o.allowance !== 0 && (
                          <ToggleButtonGroup
                            value={o.allowanceUnit}
                            exclusive
                            size="small"
                            className="mb-2"
                            onChange={(e, newValue) =>
                              newValue &&
                              handleSetObj(i, "allowanceUnit", newValue)
                            }
                          >
                            <ToggleButton value="h">h</ToggleButton>
                            <ToggleButton value="d">d</ToggleButton>
                            <ToggleButton value="w">w</ToggleButton>
                            <ToggleButton value="m">m</ToggleButton>
                          </ToggleButtonGroup>
                        )}
                      </Grid>
                      {o.allowance === null && (
                        <>
                          <Grid item xs={6} />
                          <Grid item xs={6}>
                            <Typography variant="caption" color="error">
                              Please specify allowance duration.
                            </Typography>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  )}
                </Card>
              </Grid>
              <Grid item xs={1} className="mb-2">
                <Grid container direction="column" wrap="nowrap">
                  {/* {props.obj.length > 1 && (
                    <Grid item>
                      <Tooltip
                        arrow
                        title="Up"
                        placement={i === 0 ? "top" : "bottom"}
                      >
                        <span>
                          <IconButton
                            disabled={i === 0}
                            onClick={(e) => handleMoveItem(i, -1)}
                          >
                            <ArrowUpward />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Grid>
                  )}
                  {props.obj.length > 1 && (
                    <Grid item>
                      <Tooltip
                        arrow
                        title="Down"
                        placement={i === 0 ? "top" : "bottom"}
                      >
                        <span>
                          <IconButton
                            disabled={i === props.obj.length - 1}
                            onClick={(e) => handleMoveItem(i, 1)}
                          >
                            <ArrowDownward />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Grid>
                  )} */}
                  {props.obj.length > 0 && (
                    <Grid item className="pt-3">
                      <Tooltip arrow title="Delete" placement="right">
                        <span>
                          <IconButton
                            onClick={(e) => {
                              handleDeleteItem(i);
                            }}
                          >
                            <DeleteForever />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        <Grid item xs={12}>
          <Divider>
            <ButtonGroup>
              <Button
                disabled={Object.keys(props.surveyEntities).length === 0}
                variant="outlined"
                size="small"
                color="inherit"
                startIcon={<Add />}
                className="border-dashed"
                onClick={() => handleAddItem()}
              >
                {t("Add measurement")}
              </Button>
            </ButtonGroup>
          </Divider>
        </Grid>
      </Grid>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default StudiesEditTimepointSurveys;
