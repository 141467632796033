// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React from "react";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
// -------------------------------------------------
// Redux
import { ticketsSelectors } from "../../redux/reducers/tickets";
// -------------------------------------------------
// Basic elements
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
// -------------------------------------------------
// Components
import TaskListItemNow from "./Tasks_ListItemNow";
// -------------------------------------------------
// Transitions
import Fade from "@mui/material/Fade";
// =================================================
// FUNCTIONAL COMPONENT
const TasksListToDoNow = () => {
  const { t } = useTranslation("components", {
    keyPrefix: "tasks.Tasks_ListToDoNow",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const currentUser = useSelector((state) => state.user.currentUser);
  const ghostUser = useSelector((state) => state.user.ghostUser);
  const ticketList = useSelector((state) => ticketsSelectors.selectAll(state));
  const ticketsStatus = useSelector((state) => state.tickets.status);
  const surveysStatus = useSelector((state) => state.surveys.status);
  const ticketAvailabilityList = useSelector(
    (state) => state.tickets.ticketAvailabilityList,
  );
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Filters
  const userIdFilter = (task) => currentUser && task.userId === currentUser._id;
  const ghostFilter = (task) =>
    !ghostUser && task.completer !== "participant" ? false : true;
  const isCompletedFilter = (task) => {
    return task.isCompleted;
  };
  const remainVisibleFilter = (task) => {
    return task.remainVisible;
  };
  const isAvailableFilter = (task) => {
    return DateTime.fromISO(task.dateAvailable).diffNow().valueOf() < 0;
  };
  const toDoNowFilter = (task) => {
    return (
      DateTime.fromISO(task.dateAvailable).diffNow().valueOf() < 0 &&
      DateTime.fromISO(task.dateExpire ? task.dateExpire : "2199-12-31")
        .diffNow()
        .valueOf() > 0
    );
  };
  const levelFilter = (task) =>
    task.level === 1 ||
    task.level <=
      Math.min(
        ...ticketList
          .filter(ghostFilter)
          .filter((task) => !isCompletedFilter(task))
          .map((task) => task.level),
      ) ||
    ghostUser;
  // ===============================================
  // RENDER COMPONENT
  return (
    <List>
      {ticketList
        .filter(userIdFilter)
        .filter(ghostFilter)
        .some(
          (task) =>
            (remainVisibleFilter(task) && isAvailableFilter(task)) ||
            (!isCompletedFilter(task) && toDoNowFilter(task)),
        ) ? (
        <>
          <ListSubheader className="border-bottom">
            <Typography variant="h5" className="my-2">
              {t("Available now")}
            </Typography>
          </ListSubheader>
          {ticketList
            .filter(userIdFilter)
            .filter(ghostFilter)
            .filter(
              (task) =>
                (remainVisibleFilter(task) && isAvailableFilter(task)) ||
                (!isCompletedFilter(task) && toDoNowFilter(task)),
            )
            .filter(levelFilter)
            .sort((a, b) => (a.dateAvailable < b.dateAvailable ? -1 : 1))
            .map((task) => (
              <TaskListItemNow
                key={task._id}
                task={task}
                taskAvailability={ticketAvailabilityList.find(
                  (item) => item._id === task._id,
                )}
              />
            ))}
        </>
      ) : ticketsStatus === "loading" || surveysStatus === "loading" ? (
        <Fade in={true}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item className="my-3 text-center">
              <img
                src="images/icon-wait.gif"
                alt={t("Loading...")}
                className="w-50"
              />
            </Grid>
            <Grid item>
              <Typography variant="overline">{t("Loading...")}</Typography>
            </Grid>
          </Grid>
        </Fade>
      ) : ticketsStatus === "failed" ? (
        <Fade in={true}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item className="my-3 text-center">
              <img
                src="images/icon-stranded.png"
                alt={t("An error occurred, sorry.")}
                className="w-50"
              />
            </Grid>
            <Grid item>
              <Typography variant="overline">
                {t("An error occurred. Sorry.")}
              </Typography>
            </Grid>
          </Grid>
        </Fade>
      ) : (
        <Fade in={true}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item className="my-3 text-center">
              <img
                src="images/icon-tick-done.png"
                alt={t("All tasks done!")}
                className="w-50"
              />
            </Grid>
            <Grid item>
              <Typography variant="overline">
                {t("All tasks done! Thanks.")}
              </Typography>
            </Grid>
          </Grid>
        </Fade>
      )}
    </List>
  );
};
// =================================================
// EXPORT COMPONENT
export default TasksListToDoNow;
