// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------
// Support functions
import { validateSurveyItem } from "../../supportFunc/validateSurveyItem";
// -------------------------------------------------
// Component elements
import ArticleFormControl from "./ArticleFormControl";
// -------------------------------------------------
// Redux actions
import {
  appendValidation,
  setFormSurveyLogicByAlias,
  selectSingleResponseByAlias,
} from "../../redux/reducers/form";
import { setSingleTaskResponseByAlias } from "../../redux/reducers/taskResponses";
import { setSinglePreviewResponseByAlias } from "../../redux/reducers/previewResponses";
import { updateTicketByIdWithKeyValue } from "../../redux/reducers/tickets";
// -------------------------------------------------
// Basic elements
import TextField from "@mui/material/TextField";
import TableCell from "@mui/material/TableCell";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
// =================================================
// FUNCTIONAL COMPONENT
const TextboxGridCell = (props) => {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Local state
  const [localValidation, setLocalValidation] = useState(null);
  const [localValue, setLocalValue] = useState("");
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const value = useSelector((state) =>
    selectSingleResponseByAlias(state, props.alias),
  );
  const formTicketId = useSelector((state) => state.form.ticketId);
  const responseCollection = useSelector(
    (state) =>
      state.tickets.currentTicket &&
      state.tickets.currentTicket.responseCollection,
  );
  const responseId = useSelector(
    (state) =>
      state.tickets.currentTicket && state.tickets.currentTicket.responseId,
  );
  const isEnabled = useSelector(
    (state) => state.form.isEnabled[props.article._id],
  );
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Initialize validation upon render
  useEffect(() => {
    // If this items is forced to be shown, there is no need to initialize and perform validation
    if (props.doShow) {
      return;
    }
    // Set local state
    setLocalValue(value !== null ? value : "");
    // Validate answer(s)
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value,
    );
    if (validation) {
      setLocalValidation(validation);
      dispatch(appendValidation({ validation }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------
  // Updates the state when an answer is given
  const handleSetSingleResponse = async (alias, value) => {
    // Set answer (even if it is not a valid response)
    switch (responseCollection) {
      case "taskResponses":
        await dispatch(
          setSingleTaskResponseByAlias({
            responseId,
            alias,
            value,
            disablemetadata: true,
          }),
        );
        break;
      case "previewResponses":
        await dispatch(
          setSinglePreviewResponseByAlias({ responseId, alias, value }),
        );
        break;
      default:
        return;
    }
    // Set ticket 'hasStarted'
    dispatch(
      updateTicketByIdWithKeyValue({
        ticketId: formTicketId,
        key: "hasStarted",
        value: true,
      }),
    );
    // Evaluate survey logic after this response
    dispatch(setFormSurveyLogicByAlias({ alias }));
    // Validate answer
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value,
    );
    setLocalValidation(validation);
    dispatch(appendValidation({ validation }));
  };
  // -------------------------------------------------
  // Removes leading zeros only if followed by a digit ("007" → "7", "0123.456" → "123.456").
  function sanitizeNumberInput(value) {
    // Allow "0", decimals like "0.452", and scientific notation like "0e5"
    if (/^0(\.\d+|[eE][-+]?\d+)?$/.test(value)) {
      return value;
    }
    // Remove leading zeros only for whole numbers and numbers with decimals
    return value.replace(/^0+(?=\d)/, "");
  }
  // -------------------------------------------------
  // Render input adornments
  const inputAdornment =
    props.article.options &&
    props.article.options.startAdornment &&
    props.article.options.endAdornment
      ? {
          startAdornment: (
            <InputAdornment position="start">
              {props.article.options.startAdornment}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {props.article.options.endAdornment}
            </InputAdornment>
          ),
        }
      : props.article.options &&
          props.article.options.startAdornment &&
          !props.article.options.endAdornment
        ? {
            startAdornment: (
              <InputAdornment position="start">
                {props.article.options.startAdornment}
              </InputAdornment>
            ),
          }
        : props.article.options &&
            !props.article.options.startAdornment &&
            props.article.options.endAdornment
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  {props.article.options.endAdornment}
                </InputAdornment>
              ),
            }
          : {};
  // ===============================================
  // RENDER COMPONENT
  return (
    (isEnabled || props.doShow) && (
      <TableCell
        className={props.isLastColumn ? "py-1 px-0" : "py-1 ps-0 pe-2"}
      >
        <ArticleFormControl
          article={props.article}
          required={!props.articleRequired && props.required}
          isInvalid={localValidation && localValidation.isInvalid}
          validationMsg={localValidation && localValidation.msg[props.alias]}
        >
          <TextField
            disabled={props.uneditable}
            hiddenLabel={!props.inputLabel}
            name={props.alias}
            type={props.type}
            label={props.inputLabel}
            value={localValue}
            color="secondary"
            variant="filled"
            className="bg-white answer-text-green mt-1"
            InputProps={{
              ...inputAdornment,
              inputProps: {
                inputMode: props.type === "number" ? "decimal" : "text",
              },
            }}
            sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
            onInput={(e) => {
              // Check that if the input type is number, the value is a number
              if (props.type === "number" && !e.target.value) {
                setLocalValue("0");
                handleSetSingleResponse(props.alias, "0");
                return;
              }
              if (props.type === "number") {
                setLocalValue(sanitizeNumberInput(e.target.value));
                handleSetSingleResponse(
                  props.alias,
                  sanitizeNumberInput(e.target.value),
                );
              } else {
                setLocalValue(e.target.value);
                handleSetSingleResponse(props.alias, e.target.value);
              }
            }}
            onWheel={(e) => {
              if (document.activeElement === e.target) {
                e.target.blur(); // Blur the input to stop unwanted changes
                setTimeout(() => e.target.focus(), 0); // Restore focus without scroll effect
              }
            }}
          />
          {props.type === "text" &&
            props.article.validation &&
            props.article.validation.map(
              (v) =>
                v.type === "maxCharCount" && (
                  <Typography
                    variant="caption"
                    color={
                      localValue.length > v.test ? "error" : "textSecondary"
                    }
                    textAlign="end"
                  >{`${localValue.length}/${v.test}`}</Typography>
                ),
            )}
        </ArticleFormControl>
      </TableCell>
    )
  );
};
// =================================================
// EXPORT COMPONENT
export default TextboxGridCell;
