// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { DateTime } from "luxon";
// -------------------------------------------------
// Component elements
import FormRoot from "../components/Form_Root";
// -------------------------------------------------
// Contexts
import { useAuth } from "../contexts/auth";
import { client, rooturl } from "../api-routes/client";
// -------------------------------------------------
// Redux
import {
  fetchPublicSurveyById,
  publicSurveysSelectors,
} from "../redux/reducers/publicSurveys";
import { setCurrentTicketByObject } from "../redux/reducers/tickets";
import { setFormStatus } from "../redux/reducers/form";
// -------------------------------------------------
// Basic elements
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
// =================================================
// FUNCTIONAL COMPONENT
function AnonymousTasks(props) {
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // context
  const { currentAuth } = useAuth();
  // -----------------------------------------------
  // Browser URL location
  const location = useLocation();
  // -----------------------------------------------
  // Other variables
  const [studyId, setStudyId] = useState(null);
  const [timepointId, setTimepointId] = useState(null);
  const [measurementId, setMeasurementId] = useState(null);
  const [surveyId, setSurveyId] = useState(null);
  const [userHash, setUserHash] = useState(null);
  const [openDialog, setOpenDialog] = useState(true);
  const [email, setEmail] = useState("");
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const currentSurvey = useSelector((state) =>
    publicSurveysSelectors.selectById(state, surveyId && surveyId),
  );
  const currentTicket = useSelector((state) => state.tickets.currentTicket);
  const isCompleted = useSelector(
    (state) =>
      state.tickets.currentTicket && state.tickets.currentTicket.isCompleted,
  );
  // -----------------------------------------------
  // Extract the survey id from the URL
  useEffect(() => {
    const unpackJWT = async (t) => {
      const response = await client.get(
        `${rooturl}/public/crypto/verify/${token}`,
        null,
      );
      if (response) {
        setStudyId(response.data.studyId);
        setTimepointId(response.data.timepointId);
        setMeasurementId(response.data.measurementId);
        setSurveyId(response.data.surveyId);
      }
    };
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    unpackJWT(token);
  }, [location.search]);
  // -----------------------------------------------
  // Once we have all the IDs, load the survey
  useEffect(() => {
    if (
      studyId &&
      timepointId &&
      measurementId &&
      surveyId &&
      userHash &&
      !openDialog
    ) {
      loadSurvey();
    }
  }, [studyId, timepointId, measurementId, surveyId, userHash, openDialog]); // eslint-disable-line
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  const hashEmail = async (email) => {
    const response = await client.post(`${rooturl}/public/crypto/hash`, null, {
      data: email.toLowerCase(),
    });
    return response.data.hash;
  };
  const handleSubmit = async () => {
    if (email.trim()) {
      const hashedEmail = await hashEmail(email);
      setUserHash(hashedEmail);
      setOpenDialog(false);
    }
  };
  const loadSurvey = async () => {
    // Check if ticket exists
    let res, ticket;
    res = await dispatch(
      fetchPublicSurveyById({
        requestingUser: currentAuth,
        surveyId: surveyId,
        upsert: true,
      }),
    );
    if (res.payload && res.payload.survey) {
      // Set artificial ticket
      ticket = {
        _id: uuid(),
        isDummy: true,
        userId: userHash ? userHash : "anonymous",
        studyId: studyId,
        timepointId: timepointId,
        measurementId: measurementId,
        groupId: null,
        surveyId: surveyId,
        responseId: uuid(),
        completer: "anonymous",
        remainVisible: true,
        remainEditable: true,
        level: 1,
        entryNumber: 1,
        repeat: 1,
        interval: 1,
        intervalUnits: "d",
        availability: 1,
        availabilityUnits: "d",
        allowance: 1,
        allowanceUnits: "d",
        responseCollection: "taskResponses",
        dateAvailable: DateTime.now().startOf("second").toUTC().toISO({
          suppressMilliseconds: true,
        }),
        dateDue: DateTime.now()
          .plus({ days: 365 })
          .startOf("second")
          .toUTC()
          .toISO({
            suppressMilliseconds: true,
          }),
        dateExpire: DateTime.now()
          .plus({ days: 365 })
          .startOf("second")
          .toUTC()
          .toISO({
            suppressMilliseconds: true,
          }),
        hasStarted: false,
        isCompleted: false,
        viewIdx: 0,
        notificationStatus: "idle",
      };
    }
    dispatch(setCurrentTicketByObject({ ticket }));
    // Set form status to "idle" so it initializes
    dispatch(setFormStatus({ status: "idle" }));
  };
  // -----------------------------------------------
  // Other variables
  return (
    <div className="m-4">
      <Dialog open={openDialog}>
        <DialogContent>
          <TextField
            fullWidth
            label="Enter your email address to continue"
            type="email"
            value={email}
            color="secondary"
            variant="filled"
            size="small"
            className="mb-2 mt-1"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            onKeyDown={(e) => {
              if (
                e.key === "Enter" &&
                !(!email.trim() || !/\S+@\S+\.\S+/.test(email))
              ) {
                handleSubmit(); // Trigger handleSubmit when Enter is pressed
              }
            }}
          />
          <Typography variant="body2">
            Your email address is securely transformed into a code so we can
            link your survey responses. For privacy and security reasons we will
            not store your actual email address.
          </Typography>
          <Button
            disabled={!email.trim() || !/\S+@\S+\.\S+/.test(email)} // Email validation regex
            variant="contained"
            onClick={handleSubmit}
            color="primary"
            className="d-block mt-2"
          >
            Okay
          </Button>
        </DialogContent>
      </Dialog>
      {/* Ensure all the necessary data is available and the form is not completed */}
      {currentSurvey && currentTicket && !isCompleted && <FormRoot />}
    </div>
  );
}
// =================================================
// EXPORT COMPONENT
export default AnonymousTasks;
