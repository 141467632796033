// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
// -------------------------------------------------
// Component elements
import AppDrawerPlaceholder from "./App_DrawerPlaceholder";
import StudiesContentInfo from "./studies/Studies_ContentInfo";
import StudiesContentGroups from "./studies/Studies_ContentGroups";
import StudiesContentInvestigators from "./studies/Studies_ContentInvestigators";
import StudiesContentTimepointItem from "./studies/Studies_ContentTimepointItem";
import StudiesContentTickets from "./studies/Studies_ContentTickets";
// -------------------------------------------------
// Contexts
import { useAuth } from "../contexts/auth";
import { useSocket } from "../contexts/socket";
// -------------------------------------------------
// Redux
import { patchCurrentStudy } from "../redux/reducers/studies";
import { toggleSecDrawer } from "../redux/reducers/ui";
import { surveysSelectors } from "../redux/reducers/surveys";
import {
  ticketsSelectors,
  postTicketList,
  patchTicketList,
  deleteTicketListById,
} from "../redux/reducers/tickets";
// -------------------------------------------------
// Basic elements
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Timeline from "@mui/lab/Timeline";
import Collapse from "@mui/material/Collapse";
import { TransitionGroup } from "react-transition-group";
// -------------------------------------------------
// Support functions
import { getCreateUpdateDeleteTicketList } from "../supportFunc/getCreateUpdateDeleteTicketList";
// =================================================
// FUNCTIONAL COMPONENT
// -----------------------------------------------
const StudiesContent = (props) => {
  const { t } = useTranslation("components", { keyPrefix: "Studies_Content" });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Context
  const { currentAuth } = useAuth();
  const { socket } = useSocket();
  // -----------------------------------------------
  // Local State
  const [recreateTickets, setRecreateTickets] = useState({
    all: true,
    started: false,
    completed: false,
  });
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const surveyEntities = useSelector((state) =>
    surveysSelectors.selectEntities(state),
  );
  const studiesStatus = useSelector((state) => state.studies.status);
  const surveysStatus = useSelector((state) => state.surveys.status);
  const defaultStudyId = useSelector(
    (state) => state.studies.defaultStudy && state.studies.defaultStudy._id,
  );
  const ticketList = useSelector((state) => ticketsSelectors.selectAll(state));
  // ===============================================
  // FUNCTION: MUTATE TIMEPOINTS
  // -----------------------------------------------
  // Handles copying a timepoint
  const handleCopyTimepoint = (idx) => {
    // Deep clone the object
    let newStudy = JSON.parse(JSON.stringify(props.currentStudy));
    let element = JSON.parse(JSON.stringify(newStudy.timepointList[idx]));
    element._id = uuid();
    newStudy.timepointList.splice(idx + 1, 0, element);
    // Update the database
    handlePatchCurrentStudy(newStudy);
  };
  // -----------------------------------------------
  // Handles moving a article
  const handleMoveTimepoint = (idx, direction) => {
    // Deep clone the object
    let newStudy = JSON.parse(JSON.stringify(props.currentStudy));
    let element = newStudy.timepointList[idx];
    newStudy.timepointList.splice(idx, 1);
    newStudy.timepointList.splice(idx + direction, 0, element);
    // Update the database
    handlePatchCurrentStudy(newStudy);
  };
  // -----------------------------------------------
  // Handles deleting a article
  const handleDeleteTimepoint = (idx) => {
    // Deep clone the object
    let newStudy = JSON.parse(JSON.stringify(props.currentStudy));
    newStudy.timepointList.splice(idx, 1);
    // Update the database
    handlePatchCurrentStudy(newStudy);
  };
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Edits the current study after it's been edited in the top drawer
  const handleMutateCurrentStudy = async (method, obj, idx) => {
    let currentTimepoint, newUserIdList, idxUser;
    // Make a copy of the study object
    let newStudy = JSON.parse(JSON.stringify(props.currentStudy));
    // Check what to change
    switch (method) {
      case "info":
        // Replace the info key values
        newStudy = { ...newStudy, ...obj };
        break;
      case "groupList":
        // Replace the groupList
        newStudy = { ...newStudy, groupList: obj };
        break;
      case "timepoint":
        // Sort the surveys in the timepoint by their level
        let newObj = JSON.parse(JSON.stringify(obj));
        newObj.measurementList = newObj.measurementList
          ? newObj.measurementList
              .sort((a, b) =>
                surveyEntities[a.surveyId].acronym.localeCompare(
                  surveyEntities[b.surveyId].acronym,
                ),
              )
              .sort((a, b) => (a.delay < b.delay ? -1 : 1))
              .sort((a, b) => (a.level < b.level ? -1 : 1))
          : null;
        // Check if we need to insert or replace the timepoint
        currentTimepoint = newStudy.timepointList[idx];
        if (currentTimepoint && currentTimepoint._id === newObj._id) {
          // Replace
          newStudy.timepointList[idx] = newObj;
        } else {
          // insert
          newStudy.timepointList.splice(idx, 0, newObj);
        }
        const { ticketListToPost, ticketListToPatch, ticketListToDelete } =
          getCreateUpdateDeleteTicketList(
            newStudy,
            newObj,
            ticketList,
            props.enrolledConsumerList,
            recreateTickets.completed,
          );
        if (ticketListToPost.length > 0) {
          await dispatch(
            postTicketList({
              socket,
              requestingUser: currentAuth,
              body: {
                data: ticketListToPost,
                meta: { ticketsToCreate: "new" },
              },
            }),
          );
        }
        if (ticketListToPatch.length > 0) {
          // Patch the new ticketlist
          await dispatch(
            patchTicketList({
              socket,
              requestingUser: currentAuth,
              body: {
                data: ticketListToPatch,
              },
            }),
          );
        }
        if (ticketListToDelete.length > 0) {
          await dispatch(
            deleteTicketListById({
              socket,
              requestingUser: currentAuth,
              body: { data: ticketListToDelete.map((t) => t._id) },
            }),
          );
        }
        break;
      case "add-investigator":
        newUserIdList = [...newStudy.userIdList];
        newUserIdList.push(obj.userId);
        newStudy = { ...newStudy, userIdList: newUserIdList };
        break;
      case "delete-investigator":
        newUserIdList = [...newStudy.userIdList];
        idxUser = newUserIdList.indexOf(obj.userId);
        if (idxUser > -1) {
          newUserIdList.splice(idxUser, 1);
        }
        newStudy = { ...newStudy, userIdList: newUserIdList };
        break;
      default:
        return;
    }
    handlePatchCurrentStudy(newStudy);
  };
  // -----------------------------------------------
  // Handles saving the current study to the database
  const handlePatchCurrentStudy = async (newStudy) => {
    // Update the database
    await dispatch(
      patchCurrentStudy({
        socket,
        requestingUser: currentAuth,
        body: {
          meta: { isDefaultStudy: newStudy._id === defaultStudyId },
          data: newStudy,
        },
      }),
    );
    // Close the drawer
    dispatch(toggleSecDrawer({ isOpen: false }));
  };
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return !props.currentStudy || surveysStatus === "loading" ? (
    <AppDrawerPlaceholder
      imageURL={
        studiesStatus === "loading" || surveysStatus === "loading"
          ? "images/icon-wait.gif"
          : "images/icon-choose.png"
      }
      imageAlt={
        studiesStatus === "loading" || surveysStatus === "loading"
          ? t("Loading, please wait...")
          : t("Choose a study from the list")
      }
      title={
        studiesStatus === "loading" || surveysStatus === "loading"
          ? t("Loading, please wait...")
          : t("Choose a study from the list")
      }
    />
  ) : (
    <div className="mb-2">
      <StudiesContentInfo
        info={{
          inDevelopment: props.currentStudy.inDevelopment,
          name: props.currentStudy.name,
          acronym: props.currentStudy.acronym,
          userIdList: props.currentStudy.userIdList,
          createdAt: props.currentStudy.createdAt,
          url: props.currentStudy.url,
        }}
        canBeEdited={
          currentUser &&
          (currentUser.primaryRole === "superuser" ||
            (currentUser.primaryRole === "admin" &&
              props.currentStudy.userIdList.includes(currentUser._id)))
        }
        numberOfEnrolledConsumers={props.numberOfEnrolledConsumers}
        numberOfStudyResponses={props.numberOfStudyResponses}
        currentStudyId={props.currentStudy._id}
        handleMutateCurrentStudy={handleMutateCurrentStudy}
      />
      <StudiesContentInvestigators
        studyId={props.currentStudy._id}
        userIdList={props.currentStudy.userIdList}
        handleMutateCurrentStudy={handleMutateCurrentStudy}
      />
      <StudiesContentGroups
        currentStudyId={props.currentStudy._id}
        currentGroupList={props.currentStudy.groupList}
        timepointList={props.currentStudy.timepointList}
        canBeEdited={
          props.numberOfEnrolledConsumers > -1 &&
          props.numberOfStudyResponses > -1 &&
          currentUser &&
          (currentUser.primaryRole === "superuser" ||
            (currentUser.primaryRole === "admin" &&
              props.currentStudy.userIdList.includes(currentUser._id)))
        }
        handleMutateCurrentStudy={handleMutateCurrentStudy}
      />
      <Card className="mb-3 p-3">
        <Typography variant="h3" className="mb-2">
          {t("Timepoints")}
        </Typography>
        <TransitionGroup>
          <Collapse>
            <Timeline className="w-100 align-items-center p-0 m-0">
              {props.currentStudy.timepointList.map((timepoint, i) => (
                <StudiesContentTimepointItem
                  key={timepoint._id}
                  canBeEdited={
                    props.numberOfEnrolledConsumers > -1 &&
                    props.numberOfStudyResponses > -1 &&
                    currentUser &&
                    (currentUser.primaryRole === "superuser" ||
                      (currentUser.primaryRole === "admin" &&
                        props.currentStudy.userIdList.includes(
                          currentUser._id,
                        )))
                  }
                  currentStudy={props.currentStudy}
                  timepoint={timepoint}
                  currentGroupList={props.currentStudy.groupList}
                  enrolledConsumerList={props.enrolledConsumerList}
                  surveyEntities={surveyEntities}
                  idx={i}
                  hasOneItem={props.currentStudy.timepointList.length === 1}
                  isLast={i === props.currentStudy.timepointList.length - 1}
                  recreateTickets={recreateTickets}
                  setRecreateTickets={setRecreateTickets}
                  handleCopy={handleCopyTimepoint}
                  handleMove={handleMoveTimepoint}
                  handleDelete={handleDeleteTimepoint}
                  handleMutateCurrentStudy={handleMutateCurrentStudy}
                />
              ))}
            </Timeline>
          </Collapse>
        </TransitionGroup>
      </Card>
      <StudiesContentTickets currentStudy={props.currentStudy} />
    </div>
  );
};
// =================================================
// EXPORT COMPONENT
export default StudiesContent;
