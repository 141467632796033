// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { DateTime } from "luxon";
import customParseFormat from "dayjs/plugin/customParseFormat";
// -------------------------------------------------
// Support functions
import { validateSurveyItem } from "../../supportFunc/validateSurveyItem";
// -------------------------------------------------
// Component elements
import ArticleFormControl from "./ArticleFormControl";
// -------------------------------------------------
// Redux actions
import {
  initValidationByAlias,
  appendValidation,
  setFormSurveyLogicByAlias,
  selectSingleResponseByAlias,
} from "../../redux/reducers/form";
import { setSingleTaskResponseByAlias } from "../../redux/reducers/taskResponses";
import { setSinglePreviewResponseByAlias } from "../../redux/reducers/previewResponses";
import { updateTicketByIdWithKeyValue } from "../../redux/reducers/tickets";
// -------------------------------------------------
// Basic elements
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
// -------------------------------------------------
// Define slide transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
// =================================================
// FUNCTIONAL COMPONENT
const TimeSelectorItem = (props) => {
  // eslint-disable-next-line
  const { t } = useTranslation("components", {
    keyPrefix: "form.TimeSelectorItem",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const value = useSelector((state) =>
    selectSingleResponseByAlias(state, props.alias),
  );
  const formTicketId = useSelector((state) => state.form.ticketId);
  const responseCollection = useSelector(
    (state) =>
      state.tickets.currentTicket &&
      state.tickets.currentTicket.responseCollection,
  );
  const responseId = useSelector(
    (state) =>
      state.tickets.currentTicket && state.tickets.currentTicket.responseId,
  );
  const isEnabled = useSelector(
    (state) => state.form.isEnabled[props.article._id],
  );
  // -----------------------------------------------
  // Local state
  const [modalOpen, setModalOpen] = useState(false);
  const [localValidation, setLocalValidation] = useState(null);
  const [localValue, setLocalValue] = useState(null);
  const [amPmValue, setAmPmValue] = useState(0);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  const scrollButtonsIntoView = (t, ampm) => {
    let el = document.getElementById(
      `${props.article._id}_hour${t.hour - ampm}`,
    );
    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    el = document.getElementById(`${props.article._id}_minute${t.minute}`);
    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  // Initialize upon render
  useEffect(() => {
    // If this items is forced to be shown, there is no need to initialize and perform validation
    if (props.doShow) {
      return;
    }
    // Set local state
    let tmp, thisAmPmValue;
    if (value !== null && value !== "") {
      tmp = DateTime.fromISO(value).toObject();
      thisAmPmValue = tmp.hour < 12 ? 0 : 12;
      scrollButtonsIntoView(tmp, thisAmPmValue);
      setLocalValue({ hour: tmp.hour, minute: tmp.minute });
      setAmPmValue(tmp.hour < 12 ? 0 : 12);
    } else if (props.article.options.initialValue) {
      tmp = DateTime.fromISO(props.article.options.initialValue).toObject();
      thisAmPmValue = tmp.hour < 12 ? 0 : 12;
      scrollButtonsIntoView(tmp, thisAmPmValue);
      setLocalValue({ hour: tmp.hour, minute: tmp.minute });
      setAmPmValue(thisAmPmValue);
    } else {
      setLocalValue(null);
    }
    // When article is disabled, revert back to inital value
    if (!isEnabled) {
      handleSetSingleResponse(props.alias, null);
      return;
    }
    // Validate answer(s)
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value,
    );
    if (validation) {
      setLocalValidation(validation);
      dispatch(appendValidation({ validation }));
    }
    // Clear any validation when component unmounts
    return () => {
      dispatch(initValidationByAlias({ alias: props.alias }));
    };
  }, [isEnabled, value, props.article, modalOpen]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------
  // Updates the state when an answer is given
  const handleSetSingleResponse = async (alias, value) => {
    // Set value
    if (value === undefined) {
      value = null;
    }
    // if (value !== null) {
    //   value = value.format("HH:mm");
    // }
    // Set answer (even if it is not a valid response)
    switch (responseCollection) {
      case "taskResponses":
        await dispatch(
          setSingleTaskResponseByAlias({ responseId, alias, value }),
        );
        break;
      case "previewResponses":
        await dispatch(
          setSinglePreviewResponseByAlias({ responseId, alias, value }),
        );
        break;
      default:
        return;
    }
    // Evaluate survey logic after this response
    dispatch(setFormSurveyLogicByAlias({ alias }));
    // Set ticket 'hasStarted'
    dispatch(
      updateTicketByIdWithKeyValue({
        ticketId: formTicketId,
        key: "hasStarted",
        value: true,
      }),
    );
    // Validate answer
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value,
    );
    setLocalValidation(validation);
    dispatch(appendValidation({ validation }));
  };
  // -------------------------------------------------
  // Gets a descriptor of the time of day
  function getTimeOfDay(Obj) {
    const hour = Obj && isValidNumber(Obj.hour) ? Obj.hour : null;
    if (hour >= 0 && hour < 6) {
      return " (at night)";
    } else if (hour >= 6 && hour < 12) {
      return " (morning)";
    } else if (hour >= 12 && hour < 18) {
      return " (afternoon)";
    } else if (hour >= 18 && hour < 24) {
      return " (evening)";
    } else {
      return "";
    }
  }
  // -------------------------------------------------
  function isValidNumber(value) {
    return typeof value === "number" && !isNaN(value);
  }
  // ===============================================
  dayjs.extend(customParseFormat);
  // ===============================================
  // SUBCOMPONENTS
  const RenderHourButtons = () => {
    const handleHourClick = (e) => {
      e.target.scrollIntoView({ behavior: "smooth", block: "center" });
      setLocalValue((prevState) => ({
        ...prevState,
        hour:
          (parseInt(e.target.id.replace(`${props.article._id}_hour`, ""), 10) +
            amPmValue) %
          24,
      }));
    };
    return (
      <div
        style={{
          overflowY: "auto",
          maxHeight: "250px",
          paddingTop: "109px",
          paddingBottom: "109px",
        }}
      >
        {Array.from(
          { length: props.article.options.ampm ? 12 : 24 },
          (_, i) => (
            <Button
              key={i}
              id={`${props.article._id}_hour${i}`}
              variant={
                localValue &&
                isValidNumber(localValue.hour) &&
                localValue.hour - amPmValue === i
                  ? "outlined"
                  : "text"
              }
              size="small"
              onClick={handleHourClick}
            >
              {i === 0 && props.article.options.ampm ? 12 : i}
            </Button>
          ),
        )}
      </div>
    );
  };
  const RenderMinuteButtons = () => {
    const handleMinuteClick = (e) => {
      e.target.scrollIntoView({ behavior: "smooth", block: "center" });
      setLocalValue((prevState) => ({
        ...prevState,
        minute: parseInt(
          e.target.id.replace(`${props.article._id}_minute`, ""),
          10,
        ),
      }));
    };
    return (
      <div
        style={{
          overflowY: "auto",
          maxHeight: "250px",
          paddingTop: "109px",
          paddingBottom: "109px",
        }}
      >
        {Array.from({ length: 60 / props.article.options.step }, (_, i) => (
          <Button
            key={i}
            id={`${props.article._id}_minute${i * props.article.options.step}`}
            variant={
              localValue &&
              isValidNumber(localValue.minute) &&
              localValue.minute === i * props.article.options.step
                ? "outlined"
                : "text"
            }
            size="small"
            onClick={handleMinuteClick}
          >
            {i * props.article.options.step < 10
              ? `0${i * props.article.options.step}`
              : i * props.article.options.step}
          </Button>
        ))}
      </div>
    );
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <>
      <ArticleFormControl
        article={props.article}
        required={props.required}
        isInvalid={localValidation && localValidation.isInvalid}
        validationMsg={localValidation && localValidation.msg[props.alias]}
      >
        <TextField
          hiddenLabel
          name={props.alias}
          value={
            value
              ? `${value} ${getTimeOfDay(DateTime.fromISO(value).toObject())}`
              : null
          }
          color="secondary"
          variant="filled"
          className="answer-text-green mt-1"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <AccessTimeIcon />
              </InputAdornment>
            ),
          }}
          sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
          onClick={() => {
            setModalOpen(true);
          }}
        />
      </ArticleFormControl>
      <Dialog
        open={modalOpen}
        disableEscapeKeyDown
        keepMounted
        TransitionComponent={Transition}
        onClose={() => {
          setLocalValue(null);
          setModalOpen(false);
        }}
      >
        <DialogContent
          className="p-1 text-center"
          sx={{ width: "244px", height: "304px" }}
        >
          <Grid container alignItems="center">
            {localValue &&
            isValidNumber(localValue.hour) &&
            isValidNumber(localValue.minute) ? (
              <>
                <Grid item xs={7}>
                  <Typography variant="h3" className="py-2">
                    {DateTime.fromObject(localValue).toISOTime({
                      suppressSeconds: true,
                      includeOffset: false,
                    })}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="caption" className="py-2">
                    {getTimeOfDay(localValue)}
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <Typography variant="h3" className="py-2">
                    Select time
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>

          <Grid
            container
            className="w-100 m-0 p-0"
            alignItems="center"
            justifyContent="center"
            sx={{
              height: "250px",
              maxHeight: "250px",
              overflowY: "none",
            }}
          >
            <Grid
              item
              xs={4}
              className="text-center"
              sx={{
                height: "100%",
                overflowY: "auto",
                maxHeight: "250px",
              }}
            >
              {RenderHourButtons()}
            </Grid>
            <Grid
              item
              xs={4}
              className="text-center"
              sx={{
                height: "100%",
                overflowY: "auto",
                maxHeight: "250px",
              }}
            >
              {RenderMinuteButtons()}
            </Grid>
            {props.article.options.ampm && (
              <Grid
                item
                xs={4}
                className="text-center"
                sx={{
                  height: "64px",
                  overflowY: "auto",
                  maxHeight: "250px",
                }}
              >
                <>
                  <Button
                    size="small"
                    variant={amPmValue === 0 ? "outlined" : "text"}
                    onClick={() => {
                      setAmPmValue(0);
                      setLocalValue((prevState) => {
                        return prevState && prevState.hour
                          ? {
                              ...prevState,
                              hour: prevState.hour % 12,
                            }
                          : prevState;
                      });
                    }}
                  >
                    AM
                  </Button>
                  <Button
                    size="small"
                    variant={amPmValue === 12 ? "outlined" : "text"}
                    onClick={() => {
                      setAmPmValue(12);
                      setLocalValue((prevState) => {
                        return prevState && prevState.hour
                          ? {
                              ...prevState,
                              hour: (prevState.hour % 12) + 12,
                            }
                          : prevState;
                      });
                    }}
                  >
                    PM
                  </Button>
                </>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          {props.article.options.showTodayButton && (
            <Button
              variant="outlined"
              color="inherit"
              size="small"
              className="me-3"
              onClick={() => {
                let tmp = DateTime.now().toObject();
                let step = props.article.options.step; // Assuming "step" is stored in props
                let roundedMinute = Math.round(tmp.minute / step) * step;
                if (roundedMinute >= 60) {
                  // If rounding pushes minutes to 60, increment hour and reset minutes to 0
                  tmp = DateTime.now().plus({ hours: 1 }).toObject();
                  roundedMinute = 0;
                }
                setAmPmValue(tmp.hour >= 12 ? 12 : 0);
                setLocalValue({ hour: tmp.hour, minute: roundedMinute });
              }}
            >
              Now
            </Button>
          )}
          <Button
            variant="outlined"
            color="inherit"
            size="small"
            onClick={() => {
              setLocalValue(null);
              setModalOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={
              localValue &&
              isValidNumber(localValue.hour) &&
              isValidNumber(localValue.minute)
                ? false
                : true
            }
            onClick={() => {
              handleSetSingleResponse(
                props.alias,
                DateTime.fromObject(localValue).toISOTime({
                  suppressSeconds: true,
                  includeOffset: false,
                }),
              );
              setModalOpen(false);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default TimeSelectorItem;
