// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------
// Redux
import { toggleSecDrawer } from "../../redux/reducers/ui";
import { consumersSelectors } from "../../redux/reducers/consumers";
// -------------------------------------------------
// Component elements
import StudiesEditGroups from "./Studies_EditGroups";
import TopDrawer from "../App_TopDrawer";
// -------------------------------------------------
// Basic elements
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
// -------------------------------------------------
// Icons
import Save from "@mui/icons-material/Save";
// =================================================
// FUNCTIONAL COMPONENT
// -----------------------------------------------
const StudiesContentGroups = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "studies.Studies_ContentGroups",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const studiesStatus = useSelector((state) => state.studies.status);
  const secDrawerIsOpen = useSelector((state) => state.ui.secDrawerIsOpen);
  const consumerEntities = useSelector((state) =>
    consumersSelectors.selectEntities(state),
  );
  // -----------------------------------------------
  // Local state
  const [groupList, setGroupList] = useState(props.currentGroupList);
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Handles opening the top drawer for changing survey info
  const handleEditStudyGroups = () => {
    dispatch(toggleSecDrawer({ isOpen: true, id: "studies_content-groups" }));
  };
  // -----------------------------------------------
  // Resets local state upon closing of the drawer
  useEffect(() => {
    if (!secDrawerIsOpen) {
      setGroupList(props.currentGroupList);
    }
  }, [props.currentGroupList, secDrawerIsOpen]);
  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------
  return (
    <>
      {/* ================================================== */}
      {/* TOP DRAWER */}
      <TopDrawer
        id={"studies_content-groups"}
        title={t("Study groups")}
        buttons={
          <Button
            disabled={studiesStatus === "loading"}
            color="inherit"
            startIcon={<Save />}
            className="m-2"
            onClick={() =>
              props.handleMutateCurrentStudy("groupList", groupList)
            }
          >
            {studiesStatus === "loading" ? (
              <CircularProgress size="1.5rem" className="text-light" />
            ) : (
              t("Save")
            )}
          </Button>
        }
      >
        <StudiesEditGroups
          obj={groupList}
          setObj={setGroupList}
          currentGroupIds={[
            ...Object.values(consumerEntities)
              .filter(
                (consumer) =>
                  consumer.studyEnrollmentList[0] &&
                  consumer.studyEnrollmentList[0].studyId ===
                    props.currentStudyId &&
                  consumer.studyEnrollmentList[0].groupAssignmentList[0],
              )
              .map(
                (consumer) =>
                  consumer.studyEnrollmentList[0].groupAssignmentList[0]
                    .groupId,
              ),
            ...new Set(props.timepointList.flatMap((tp) => tp.groupIdList)),
          ]}
        />
      </TopDrawer>
      {/* SECTION SHOWING CURRENT GROUPS */}
      <Card className="mb-3">
        <List disablePadding className="px-3 pt-2">
          <ListItem
            disablePadding
            secondaryAction={
              props.canBeEdited && (
                <Tooltip arrow title={t("Edit")} placement="top">
                  <IconButton
                    disabled={studiesStatus === "loading"}
                    edge="end"
                    onClick={handleEditStudyGroups}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )
            }
          >
            <ListItemText
              primary={<Typography variant="h3">{t("Groups")}</Typography>}
            />
          </ListItem>
        </List>
        <div className="ms-3">
          {props.currentGroupList.map((group) => (
            <Chip
              key={group._id}
              variant="outlined"
              size="small"
              label={group.label}
              className="me-2 mb-3"
            />
          ))}
        </div>
      </Card>
    </>
  );
};
// =================================================
// EXPORT COMPONENT
export default StudiesContentGroups;
