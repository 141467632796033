// =================================================
// IMPORT
// -------------------------------------------------
// Dependencies
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Duration } from "luxon";
// -------------------------------------------------
// Support functions
import { validateSurveyItem } from "../../supportFunc/validateSurveyItem";
// -------------------------------------------------
// Component elements
import ArticleFormControl from "./ArticleFormControl";
// -------------------------------------------------
// Redux actions
import {
  initValidationByAlias,
  appendValidation,
  setFormSurveyLogicByAlias,
  selectSingleResponseByAlias,
} from "../../redux/reducers/form";
import { setSingleTaskResponseByAlias } from "../../redux/reducers/taskResponses";
import { setSinglePreviewResponseByAlias } from "../../redux/reducers/previewResponses";
import { updateTicketByIdWithKeyValue } from "../../redux/reducers/tickets";
// -------------------------------------------------
// Basic elements
import { Picker } from "antd-mobile";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
// =================================================
// FUNCTIONAL COMPONENT
const DurationItemMobile = (props) => {
  const { t } = useTranslation("components", {
    keyPrefix: "form.TimeSelectorItemMobile",
  });
  // ===============================================
  // VARIABLES
  // -----------------------------------------------
  // Local state
  const [localValidation, setLocalValidation] = useState(null);
  const [visible, setVisible] = useState(false);
  // -----------------------------------------------
  // Redux
  const dispatch = useDispatch();
  const value = useSelector((state) =>
    selectSingleResponseByAlias(state, props.alias),
  );
  const formTicketId = useSelector((state) => state.form.ticketId);
  const responseCollection = useSelector(
    (state) =>
      state.tickets.currentTicket &&
      state.tickets.currentTicket.responseCollection,
  );
  const responseId = useSelector(
    (state) =>
      state.tickets.currentTicket && state.tickets.currentTicket.responseId,
  );
  const isEnabled = useSelector(
    (state) => state.form.isEnabled[props.article._id],
  );
  // -------------------------------------------------
  // Function to generate a range of values
  const range = (min, max, step) => {
    let arr = [];
    for (let i = min; i <= max; i += step) {
      arr.push(i);
    }
    return arr;
  };
  // -----------------------------------------------
  // CONSTANTS
  const days = range(0, 365, 1);
  const hours = range(0, 23, 1);
  const minutes = range(0, 59, 1);
  const seconds = range(0, 59, 1);
  let columnList = [];
  if (props.article.options.showDays) {
    columnList = [
      ...columnList,
      days.map((h) => {
        return { label: `${h} days`, value: h };
      }),
    ];
  }
  if (props.article.options.showHours) {
    columnList = [
      ...columnList,
      hours.map((h) => {
        return { label: `${h} hours`, value: h };
      }),
    ];
  }
  if (props.article.options.showMinutes) {
    columnList = [
      ...columnList,
      minutes.map((h) => {
        return { label: `${h} min`, value: h };
      }),
    ];
  }
  if (props.article.options.showSeconds) {
    columnList = [
      ...columnList,
      seconds.map((h) => {
        return { label: `${h} sec`, value: h };
      }),
    ];
  }
  // ===============================================
  // FUNCTIONS
  // -----------------------------------------------
  // Initialize upon render
  useEffect(() => {
    // If this items is forced to be shown, there is no need to initialize and perform validation
    if (props.doShow) {
      return;
    }
    // Set local state
    // setLocalValue(parseValue2Local(value));
    // When article is disabled, revert back to inital value
    if (!isEnabled) {
      handleSetSingleResponse(props.alias, null);
      return;
    }
    // Validate answer(s)
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value,
    );
    if (validation) {
      setLocalValidation(validation);
      dispatch(appendValidation({ validation }));
    }
    // Clear any validation when component unmounts
    return () => {
      dispatch(initValidationByAlias({ alias: props.alias }));
    };
  }, [isEnabled]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------
  const parseRawValue = (val) => {
    let obj = {};
    let i = 0;
    if (props.article.options.showDays) {
      obj = { ...obj, days: val[i] };
      i = i + 1;
    }
    if (props.article.options.showHours) {
      obj = { ...obj, hours: val[i] };
      i = i + 1;
    }
    if (props.article.options.showMinutes) {
      obj = { ...obj, minutes: val[i] };
      i = i + 1;
    }
    if (props.article.options.showSeconds) {
      obj = { ...obj, seconds: val[i] };
      i = i + 1;
    }
    return Duration.fromObject(obj).toISO();
  };
  // -------------------------------------------------
  const parseISO2Array = (iso) => {
    let dur;
    if (iso === null || iso === undefined) {
      dur = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    } else {
      dur = Duration.fromISO(iso);
    }
    let arr = [];
    if (props.article.options.showDays) {
      arr = [...arr, dur.days];
    }
    if (props.article.options.showHours) {
      arr = [...arr, dur.hours];
    }
    if (props.article.options.showMinutes) {
      arr = [...arr, dur.minutes];
    }
    if (props.article.options.showSeconds) {
      arr = [...arr, dur.seconds];
    }
    return arr;
  };
  // -------------------------------------------------
  // Updates the state when an answer is given
  const handleSetSingleResponse = async (alias, value) => {
    // Set value
    value = parseRawValue(value);
    // Set answer (even if it is not a valid response)
    switch (responseCollection) {
      case "taskResponses":
        await dispatch(
          setSingleTaskResponseByAlias({ responseId, alias, value }),
        );
        break;
      case "previewResponses":
        await dispatch(
          setSinglePreviewResponseByAlias({ responseId, alias, value }),
        );
        break;
      default:
        return;
    }
    // Evaluate survey logic after this response
    dispatch(setFormSurveyLogicByAlias({ alias }));
    // Set ticket 'hasStarted'
    dispatch(
      updateTicketByIdWithKeyValue({
        ticketId: formTicketId,
        key: "hasStarted",
        value: true,
      }),
    );
    // Validate answer
    const validation = validateSurveyItem(
      props.required,
      props.article.validation,
      props.alias,
      value,
    );
    setLocalValidation(validation);
    dispatch(appendValidation({ validation }));
  };
  // ===============================================
  // RENDER COMPONENT
  return (
    <ArticleFormControl
      article={props.article}
      required={props.required}
      isInvalid={localValidation && localValidation.isInvalid}
      validationMsg={localValidation && localValidation.msg[props.alias]}
    >
      <TextField
        hiddenLabel
        disabled={props.uneditable}
        name={props.alias}
        value={
          value
            ? Duration.fromISO(value).toHuman({
                listStyle: "long",
                unitDisplay: "short",
              })
            : ""
        }
        color="secondary"
        variant="filled"
        className="answer-text-green mt-1"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <AccessTimeIcon />
            </InputAdornment>
          ),
        }}
        sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
        onClick={(e) => {
          e.target.blur();
          if (!props.uneditable) {
            setVisible(true);
          }
        }}
      />
      <Picker
        cancelText={<Button color="inherit">{t("Cancel")}</Button>}
        confirmText={
          <Button variant="contained" color="primary">
            {t("Set")}
          </Button>
        }
        columns={columnList}
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        value={parseISO2Array(value)}
        onConfirm={(newValue) => {
          handleSetSingleResponse(props.alias, newValue);
          // setLocalValue(newValue);
        }}
      />
    </ArticleFormControl>
  );
};
// =================================================
// EXPORT COMPONENT
export default DurationItemMobile;
