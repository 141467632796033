import React, { useState, useRef, useMemo, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Scatter,
  ComposedChart,
  ReferenceArea,
  ReferenceLine,
} from "recharts";
import CustomTooltip from "./customTooltip";
import { DateTime, Duration } from "luxon";

// Helper functions
const adjustTimeTo24HourFormat = (time) => {
  // time = Math.round(time) % 24;
  // return time < 0 ? time + 24 : time;
  return DateTime.fromSeconds(time * 3600)
    .toUTC()
    .toFormat("H");
};

const convertDurationToHours = (durationString) => {
  return Duration.fromISO(durationString).as("hours");
};

const convertTimeToHours = (timeString) => {
  let d = Duration.fromISOTime(timeString).as("hours");
  return d;
};

const add24 = (d) => {
  if (d < 15) {
    d += 24;
  }
  return d;
};

// Define colors
const colors = {
  timeBeforeSleep: "#81C4FF",
  timeAwakeDuringSleep: "#81C4FF",
  timeAsleep: "#164BA7",
  timeAfterSleep: "#81C4FF",
};

const SleepBarChart = React.memo(({ data, cbtiTasks }) => {
  const [scrollPosition, setScrollPosition] = useState({ top: 0, height: 0 });
  const scrollDemoRef = useRef(null);

  const chartData = useMemo(() => {
    if (!data || !data.sleepLogTasks) return [];

    const now = new Date();

    return data.sleepLogTasks
      .sort((a, b) => a.dateAvailable.localeCompare(b.dateAvailable))
      .map((entry) => {
        // For each sleep diary entry, extract the bed time, sleep onset time, wake time, and get up time
        // Then add 24 hours to the time if it is before 15:00. This is to account for the fact that the sleep diary is not date specific.
        // Finally, calculate the difference in time between the bed time and sleep onset time, sleep onset time and wake time, wake time and get up time.
        const date = new Date(entry.dateAvailable);
        let bedTime,
          lightsOffTime,
          sleepOnsetLatency,
          sleepOnsetTime,
          finWakeTime,
          snoozeLatency,
          lightsOnTime,
          riseTime,
          tib,
          tst;
        bedTime = add24(
          convertTimeToHours(entry.taskResponse?.data.CSD3 || null),
        );
        lightsOffTime = convertTimeToHours(
          entry.taskResponse?.data.CSD4 || null,
        );
        sleepOnsetLatency = convertDurationToHours(
          entry.taskResponse?.data.CSD5 || null,
        );
        sleepOnsetTime = add24(lightsOffTime + sleepOnsetLatency);
        finWakeTime = add24(
          convertTimeToHours(entry.taskResponse?.data.CSD8 || null),
        );
        snoozeLatency = convertDurationToHours(
          entry.taskResponse?.data.CSD9 || null,
        );
        lightsOnTime = add24(finWakeTime + snoozeLatency); // eslint-disable-line
        riseTime = add24(
          convertTimeToHours(entry.taskResponse?.data.CSD10 || null),
        );
        tst =
          parseFloat(entry.taskResponse?.data.CSD_TOTAL_SLEEP_TIME || null) /
          60;
        tib = convertDurationToHours(
          entry.taskResponse?.data.CSD_TIME_IN_BED || null,
        );

        return {
          date,
          dateValue: Math.floor(date.getTime() / 86400000) * 86400000,
          completed:
            entry.taskResponse && entry.taskResponse?.data.CSD4 !== null,
          bedClock: entry.taskResponse?.data.CSD3 || null,
          riseClock: entry.taskResponse?.data.CSD10 || null,
          sleepOnsetLatency: entry.taskResponse?.data.CSD5 || null,
          waso: entry.taskResponse?.data.CSD7 || null,
          tst,
          tib,
          se: 100 * (tst / tib),
          bedTime,
          sleepOnsetTime,
          finWakeTime,
          riseTime,
          sleepQuality: entry.taskResponse?.data.CSD11 || null,
          offset: bedTime || 0,
          bar1: sleepOnsetTime - bedTime ? sleepOnsetTime - bedTime : 0,
          bar2: finWakeTime - sleepOnsetTime ? finWakeTime - sleepOnsetTime : 0,
          bar3: riseTime - finWakeTime ? riseTime - finWakeTime : 0,
          totalTime: convertDurationToHours(
            entry.taskResponse?.data.CSD_ATTEMPT_SLEEP_WINDOW || "PT0H",
          ),
          comment: entry.taskResponse?.data.CSD22 || "",
          marker: 2.5, // For the overview chart
          area: 2,
        };
      })
      .filter((entry) => entry.date <= now);
  }, [data]);

  const numDays =
    chartData && chartData.length > 0
      ? (chartData[chartData.length - 1].dateValue - chartData[0].dateValue) /
        (24 * 60 * 60 * 1000)
      : 1;
  const totalHeight = numDays * 12;

  const handleScroll = useCallback(() => {
    if (scrollDemoRef.current) {
      setScrollPosition({
        top: scrollDemoRef.current.scrollTop,
        height: scrollDemoRef.current.clientHeight,
      });
    }
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="body2" sx={{ textAlign: "center", mb: 2 }}>
        Sleep Diary Data
      </Typography>

      {chartData.length > 0 && (
        <>
          <ResponsiveContainer height={60}>
            <ComposedChart data={chartData}>
              <XAxis
                type="number"
                dataKey="dateValue"
                domain={[
                  (dataMin) => dataMin - 43200000,
                  (dataMax) => dataMax + 43200000,
                ]}
                axisLine={{ stroke: "#fff" }}
                scale="time"
                tick={{ fontSize: 10 }}
                ticks={chartData
                  .filter((entry) => entry.date.getDay() === 1)
                  .map((entry) => entry.dateValue)
                  .sort((a, b) => a - b)}
                tickFormatter={(tick) => {
                  return DateTime.fromMillis(tick).toFormat("EEEEE dd/MM");
                }}
              />
              <YAxis dataKey="marker" domain={[0, 5]} hide />
              <XAxis
                xAxisId="scrollRef"
                type="number"
                domain={[0, totalHeight]}
                hide
                allowDataOverflow
              />
              <ReferenceArea
                xAxisId="scrollRef"
                x1={scrollPosition.top}
                x2={scrollPosition.top + scrollPosition.height}
                y1={0}
                y2={5}
                stroke={colors.timeAwakeDuringSleep}
                fill={colors.timeAwakeDuringSleep}
                strokeOpacity={0}
                fillOpacity={0.3}
              />
              <Scatter
                key={`scatter-incomplete`}
                data={chartData.filter((entry) => !entry.completed)}
                fill="#FFFFFF00"
                stroke="#999999C9"
                onClick={(e) => {
                  if (scrollDemoRef.current) {
                    scrollDemoRef.current.scrollTo({
                      top:
                        (totalHeight * e.cx) / e.xAxis.width -
                        scrollDemoRef.current.clientHeight / 2,
                    });
                  }
                }}
              />

              {chartData.some((entry) => entry.completed === true) && (
                <Scatter
                  key={`scatter-complete`}
                  data={chartData.filter((entry) => entry.completed === true)}
                  fill="#118E99C9"
                  stroke="#118E99C9"
                  onClick={(e) => {
                    if (scrollDemoRef.current) {
                      scrollDemoRef.current.scrollTo({
                        top:
                          (totalHeight * e.cx) / e.xAxis.width -
                          scrollDemoRef.current.clientHeight / 2,
                      });
                    }
                  }}
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
          <div
            ref={scrollDemoRef}
            onScroll={handleScroll}
            style={{
              overflowY: "auto",
              height: "400px",
            }}
          >
            <ResponsiveContainer width="100%" height={totalHeight + 60}>
              <BarChart
                data={chartData}
                layout="vertical"
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                barSize={8}
              >
                <CartesianGrid
                  stroke="#999"
                  strokeDasharray="1 3"
                  horizontal={false}
                />
                <XAxis
                  xAxisId="topxaxes"
                  type="number"
                  axisLine={{ stroke: "#aaa" }}
                  orientation="top"
                  tick={{ fontSize: 10 }}
                  tickLine={false}
                  domain={[(dataMin) => 12, (dataMax) => 36]} // the api requires this particular function call syntax
                  ticks={[15, 18, 21, 24, 27, 30, 33]}
                  tickFormatter={(time) => adjustTimeTo24HourFormat(time)}
                />
                <XAxis
                  xAxisId="bottomxaxes"
                  type="number"
                  axisLine={{ stroke: "#aaa" }}
                  orientation="bottom"
                  tick={{ fontSize: 10 }}
                  tickLine={false}
                  domain={[(dataMin) => 12, (dataMax) => 36]} // the api requires this particular function call syntax
                  ticks={[15, 18, 21, 24, 27, 30, 33]}
                  tickFormatter={(time) => adjustTimeTo24HourFormat(time)}
                />
                <YAxis
                  yAxisId="leftyaxes"
                  type="number"
                  axisLine={{ stroke: "#aaa" }}
                  dataKey="dateValue"
                  domain={[
                    (dataMin) => dataMin - 43200000,
                    (dataMax) => dataMax + 43200000,
                  ]}
                  scale="time"
                  interval={0}
                  tickLine={false}
                  tick={{ fontSize: 10 }}
                  ticks={chartData
                    .map((entry) => entry.dateValue)
                    .sort((a, b) => a - b)}
                  tickCount={chartData.length}
                  tickFormatter={(tick) => {
                    return DateTime.fromMillis(tick).toFormat("EEEEE dd/MM");
                  }}
                />
                <YAxis
                  yAxisId="rightyaxes"
                  orientation="right"
                  type="number"
                  axisLine={{ stroke: "#aaa" }}
                  dataKey="dateValue"
                  domain={[
                    (dataMin) => dataMin - 43200000,
                    (dataMax) => dataMax + 43200000,
                  ]}
                  scale="time"
                  interval={0}
                  tickLine={false}
                  tick={{ fontSize: 10 }}
                  ticks={chartData
                    .map((entry) => entry.dateValue)
                    .sort((a, b) => a - b)}
                  tickCount={chartData.length}
                  tickFormatter={(tick) => {
                    return DateTime.fromMillis(tick)
                      .plus({ days: 1 })
                      .toFormat("EEEEE dd/MM");
                  }}
                />
                <Tooltip content={<CustomTooltip />} />
                <Bar
                  xAxisId="topxaxes"
                  yAxisId="leftyaxes"
                  dataKey="offset"
                  stackId="sleep"
                  fill="black"
                  fillOpacity={0}
                />
                <Bar
                  xAxisId="topxaxes"
                  yAxisId="leftyaxes"
                  dataKey="bar1"
                  stackId="sleep"
                  fill={colors.timeBeforeSleep}
                  radius={[10, 0, 0, 10]}
                />
                <Bar
                  xAxisId="topxaxes"
                  yAxisId="leftyaxes"
                  dataKey="bar2"
                  stackId="sleep"
                  fill={colors.timeAsleep}
                />
                <Bar
                  xAxisId="topxaxes"
                  yAxisId="leftyaxes"
                  dataKey="bar3"
                  stackId="sleep"
                  fill={colors.timeAfterSleep}
                  radius={[0, 10, 10, 0]}
                />
                {cbtiTasks.map((entry, i) => (
                  <ReferenceLine
                    key={entry._id}
                    xAxisId="topxaxes"
                    yAxisId="leftyaxes"
                    y={
                      DateTime.fromISO(entry.dateAvailable)
                        .toLocal()
                        .toMillis() -
                      86400000 / 2
                    }
                    label={{
                      value: entry.acronym,
                      position: "insideTopLeft",
                      fontSize: 10,
                      offset: 3,
                      textAnchor: "start",
                    }}
                    stroke="#888"
                    strokeDasharray="1 3"
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
    </Box>
  );
});

export default SleepBarChart;
